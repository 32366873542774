import {apiUrl} from "../utils/connectionStrings";
import {handleResponse} from "../helpers/handle-response";


export const statusService = {
    getAll, getByType, create, update, getByProjectNumber, markToDelete
};


function getAll () {
    const requestOptions = {
        method : "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch (apiUrl + "/status", requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}

function getByProjectNumber (project_number) {
    const requestOptions = {
        method : "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch (apiUrl + "/status/get_by_project_number/" + project_number, requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}

function getByType (typeId) {
    const requestOptions = {
        method : "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch (apiUrl + "/status/get_by_type/" + typeId, requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}


function create (newStatus) {
    const requestOptions = {
        method : "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body   : JSON.stringify (newStatus),
    };
    return fetch (apiUrl + "/status/create", requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}

function update (updateStatusModel) {
    const requestOptions = {
        method : "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body   : JSON.stringify(updateStatusModel),
    };
    return fetch (apiUrl + "/status/update/" + updateStatusModel.id, requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}

function markToDelete (sid, tid) {
    const requestOptions = {
        method : "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch (apiUrl + "/status/mark_to_delete/" + tid + "/" + sid, requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}

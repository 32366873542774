import {apiUrl} from "../utils/connectionStrings";
import {handleResponse} from "../helpers/handle-response";


export const projectsService = {
    getAll, getByNumber, getEmptyNumbers, createProject, updateProject
};


function getAll () {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch(apiUrl + "/projects", requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}

function getByNumber (projectNumber) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch(apiUrl + "/project/single/" + projectNumber, requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}
function createProject (newProject) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: newProject
    };
    return fetch(apiUrl + "/project/create/new" , requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}

function updateProject (changeProject) {
    const requestOptions = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(changeProject)
    };
    return fetch(apiUrl + "/project/update/" + changeProject.number , requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}




function getEmptyNumbers () {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch(apiUrl + "/project/get/get_empty_numbers", requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}



import * as React from "react";
import {AuthProvider} from "./hok/AuthProvider";
import {RequireAuth} from "./hok/RequireAuth";
import Main from "./routes/Main";
import {Route, Routes} from "react-router-dom";
import LoginPage from "./pages/LoginPage";


export default function App () {
    return <AuthProvider>
            <Routes>
                <Route path='/login' element={<LoginPage/>} />
                <Route path='*' element={
                    <RequireAuth>
                        <Main />
                    </RequireAuth>
                } />
            </Routes>
        </AuthProvider>
}
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Grid, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {formToBodySerialize} from "../../utils";
import {getNormalDate} from "../../helpers/functions";
import {useNavigate} from "react-router-dom";
import {staffService} from "../../services/staffService";
import {LayoutContext} from "../../hok/LayoutProvider";


const PersonCreate = () => {

    const [birthday, setBirthday] = useState(null)
    const [newPerson, setNewPerson] = useState({
        name: "",
        familyname: "",
        short_name: "",
        description: "",
        grade_id: "",
        telegram_id: "",
        birthday: "",
        email: "",
        phone: "",
        line_manger: "",
        profile_lnk: "",
        is_active: true,
        is_deleted: false,
        is_fired: false,
    })
    const {metaData, changeMeta} = useContext(LayoutContext)
    const personForm = useRef()
    const navigate = useNavigate()

    useEffect(() => {
        changeMeta({
            mainTitle: 'Добавить участника проекта',
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
    }, [])



    const handleChange = event => {
        newPerson[event.target.name] = event.target.value
        setNewPerson(newPerson)
    };

    const onSubmitForm = (e) => {
        e.preventDefault();
        let prsn = formToBodySerialize(new FormData(personForm.current))
        prsn.birthday === "" ? delete prsn.birthday : prsn.birthday = getNormalDate(prsn.birthday)
        console.log('prsn', prsn)
        const body = JSON.stringify(prsn)
        staffService.createPerson(body).then(_ => {
            navigate(`/staff`);

        })
    };

    //TODO: PERSONAL CREATE Необходимо валидировать данные пользователя и раскаладывать циферки, если нет данных, то поля в модели проекта не должно быть, тогда Pydantic сам поймёт что ему делать.
    // А так он думает, что поле есть, просто не правильные данные и выдаёт ошибку.
    // При этом также если есть ошибка от Pydantic научиться её принимать и обрабатывать и оставлять пользователя на странице ввода информации.

    // TODO: Проверить правильность формы и заолпнение полей сотрудника (остановился тут 25.02.23 00:52)
    return (
        <>
            <Grid container rowSpacing={2} p={2} columnSpacing={{xs: 1, sm: 2}}>
                <Grid item md={6}>
                    <Paper elevation={3} sx={{p: 2, m: 3}}>
                        <Box
                            component="form"
                            ref={personForm}
                            noValidate
                            autoComplete="off"
                        >
                            <Grid container rowSpacing={3} p={2} columnSpacing={{xs: 1, sm: 2}}>
                                <Grid item md={4}>
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        label={"Имя"}
                                        name={"name"}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField
                                        fullWidth
                                        label={"Фамилия"}
                                        name={"familyname"}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField
                                        fullWidth
                                        label={"Краткое имя"}
                                        name={"short_name"}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <TextField
                                        fullWidth
                                        id="description"
                                        label="Примечание"
                                        name={'description'}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid item md={4}>
                                    <TextField
                                        fullWidth
                                        id="telegram_id"
                                        label="Телеграм аккаунт"
                                        name={'telegram_id'}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        name={'email'}
                                        label="Email"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField
                                        fullWidth
                                        id="phone"
                                        name={'phone'}
                                        label="Телефон"
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid item md={6}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            value={birthday}
                                            label={"Дата рождения"}
                                            inputFormat='DD.MM.YY'
                                            onChange={(newValue) => {
                                                setBirthday(newValue)
                                            }}
                                            renderInput={(params) => <TextField
                                                name="birthday" {...params} sx={{width: "100%"}}/>}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item md={12}>
                                    <Button onClick={e => {
                                        onSubmitForm(e)
                                    }}>Сохранить</Button>
                                </Grid>

                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default PersonCreate;
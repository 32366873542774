import React from "react";
import {Button, ButtonGroup, Grid} from "@mui/material";
import {NavLink, Outlet} from "react-router-dom";


const HelpPage = () => {

    const buttons = [
        <Button sx={{justifyContent: "start"}} key="one"><NavLink className="nav-link" to={"/help"}>Начало
            работы</NavLink></Button>,
        <Button sx={{justifyContent: "start"}} key="two"><NavLink className="nav-link"
                                                                  to={"/help/project"}>Project</NavLink></Button>,
        <Button sx={{justifyContent: "start"}} key="three"><NavLink className="nav-link"
                                                                    to={"/help/value"}>Value</NavLink></Button>,
        <Button sx={{justifyContent: "start"}} key="three"><NavLink className="nav-link"
                                                                    to={"/help/task"}>Task</NavLink></Button>,
        <Button sx={{justifyContent: "start"}} key="three"><NavLink className="nav-link"
                                                                    to={"/help/estimate"}>Estimate</NavLink></Button>,
        <Button sx={{justifyContent: "start"}} key="three"><NavLink className="nav-link"
                                                                    to={"/help/finance"}>Finance</NavLink></Button>,
        <Button sx={{justifyContent: "start"}} key="three"><NavLink className="nav-link"
                                                                    to={"/help/team"}>Team</NavLink></Button>,
        <Button sx={{justifyContent: "start"}} key="three"><NavLink className="nav-link"
                                                                    to={"/help/invoice"}>Invoice</NavLink></Button>,

    ];

    return <Grid container>
        <Grid item md={9} justifyContent={"start"} p={3}>
            <Outlet/>
        </Grid>
        <Grid item md={3} justifyContent={"end"} display={"flex"}>
            <ButtonGroup
                orientation="vertical"
                aria-label="vertical outlined button group"
            >
                {buttons}
            </ButtonGroup>
        </Grid>
    </Grid>
}

export default HelpPage
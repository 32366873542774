import {apiUrl} from "../utils/connectionStrings";
import {handleResponse} from "../helpers/handle-response";


export const valueService = {
    getAll, get_values_by_project_id, createValue, updateValue, getValuesTreeSorted
};


function getAll() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch(apiUrl + "/values", requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}

function getValuesTreeSorted() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch(apiUrl + "/values/tree_by_parent_id", requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}



function get_values_by_project_id(projectId) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch(apiUrl + "/values/by_project_id/" + projectId, requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}

function createValue(model) {
    console.log('createValue - src/services/valueService.js:39', model)
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: model
    };
    return fetch(apiUrl + "/value", requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}

function updateValue(model, valueId) {
    console.log('createValue - src/services/valueService.js:39', model)
    const requestOptions = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(model)
    };
    return fetch(apiUrl + "/value/update/" + valueId, requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}






import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {releaseService} from "../../services/releaaseService";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Button, Chip, LinearProgress} from "@mui/material";
import {LayoutContext} from "../../hok/LayoutProvider";


const ReleasesList = () => {

    const [loading, setLoading] = useState(true)
    const [rList, setRList] = useState([])
    const {metaData, changeMeta} = useContext(LayoutContext)


    useEffect(() => {
        releaseService.getAll()
            .then(_ => {
                setRList(_)
                setLoading(false)
            })
            .catch(e => console.log("ERROR", e))
    }, [])

    useEffect(() => {
        changeMeta({
            mainTitle: "Релизы",
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
    }, [])


    if (!loading) return <>
        <Paper elevation={3} sx={{m: 3, p: 3}}>
            <Box component="div">
                <ReleaseTable rList={rList}/>
            </Box>
        </Paper>
    </>
    else return <LinearProgress/>
}
export default ReleasesList

const ReleaseTable = ({rList}) => {

    const name = 'Наименование'
    const type = 'Тип'
    const status = 'Статус'
    const requirements_fix_date = 'Фиксация состава'
    const ready_to_test_date = 'Готовность релиза'
    const uat_date = 'UAT'
    const completed = 'Готовность, %'
    const is_active = 'Активный'

    return (
        <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
                <TableRow className="pa-8">
                    <TableCell className="pa-8">{name}</TableCell>
                    <TableCell className="pa-8">{type}</TableCell>
                    <TableCell className="pa-8">{status}</TableCell>
                    <TableCell className="pa-8 w-210">{requirements_fix_date}</TableCell>
                    <TableCell className="pa-8 w-210">{ready_to_test_date}</TableCell>
                    <TableCell className="pa-8 w-210">{uat_date}</TableCell>
                    <TableCell className="pa-8 w-64">{completed}</TableCell>
                    <TableCell className="pa-8">{is_active}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rList.map(row => (
                    <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell className="pa-8"><Button href={'/release/' + row.id}>{row.name}</Button></TableCell>
                        <TableCell
                            className={row.type ? "pa-8" : "pa-8 font-line-through"}>{row.type ? row.type.name : "Тип релиза удалён"}</TableCell>
                        <TableCell className={row.status ? "pa-8" : "pa-8 font-line-through"}><Chip
                            label={row.status ? row.status.name : "Статус проекта удалён"}
                            color={row.status ? "primary" : "error"} variant="outlined"/></TableCell>
                        <TableCell className="pa-8 w-210">{row.requirements_fix_date}</TableCell>
                        <TableCell className="pa-8 w-210">{row.ready_to_test_date}</TableCell>
                        <TableCell className="pa-8 w-210">{row.uat_date}</TableCell>
                        <TableCell className="pa-8 w-64" align="right">{row.completed * 100}{"%"}</TableCell>
                        <TableCell className="pa-8">{row.is_active ? "Активный" : "Неактивный"}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}


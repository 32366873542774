import Box from "@mui/material/Box";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Button, Chip, FormControl, InputLabel, LinearProgress, MenuItem, Select} from "@mui/material";
import {AddCircleOutline} from "@mui/icons-material";
import {projectsService} from "../../services/projectsService";
import {LayoutContext} from "../../hok/LayoutProvider";
import lodash from 'lodash'

const ProjectsList = () => {

    const [activeProject, setActiveProject] = useState(null)
    const [projects, setProjects] = useState(null)
    const [isSelectStatusOpen, setIsSelectStatusOpen] = useState(false)
    const [loading, setLoading] = useState(true)


    const {metaData, changeMeta} = useContext(LayoutContext)

    useEffect(() => {
        changeMeta({
            mainTitle: "Проекты",
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
    }, [])

    useEffect(() => {
        projectsService.getAll().then(_ => {
            setProjects(_)
            setLoading(false)
        })
    }, [])

    const change_prop = (e, index, propertyName) => {
        e.preventDefault()
        const ap = lodash.cloneDeep(projects[index])
        setActiveProject(ap)
        setIsSelectStatusOpen(true)
    }

    const saveUpdates = (updated_project, index) => {
        projectsService.updateProject(updated_project).then(_ => {
            let newListProjects = lodash.cloneDeep(projects)
            newListProjects[index] = _
            setProjects(newListProjects)
            setIsSelectStatusOpen(false)
            setActiveProject(null)
        })

    }



    if (loading) {
        return <LinearProgress/>
    } else {
        return (<>
                <Paper elevation={3} sx={{m: 3, p: 3}}>
                    <Box justifyContent={'flex-end'} display={'flex'}>
                        <Button variant="outlined" href="/project/create" startIcon={<AddCircleOutline/>}>
                            Добавить новый проект
                        </Button>
                    </Box>
                    <Box component="div">
                        {projects && <BasicTable
                            projects={projects}
                            change_prop={change_prop}
                            isSelectStatusOpen={isSelectStatusOpen}
                            setIsSelectStatusOpen={setIsSelectStatusOpen}
                            activeProject={activeProject}
                            saveUpdates={saveUpdates}
                        />}
                    </Box>
                </Paper>
            </>
        )
    }
}

export {ProjectsList}


const BasicTable = ({projects, change_prop, isSelectStatusOpen, setIsSelectStatusOpen, activeProject, saveUpdates }) => {
    const number = '№'
    const name = 'Наименование'
    const type = 'Тип'
    const status = 'Статус'
    const baseline_finish = 'Завершение'
    const completed = 'Готовность, %'
    const release = 'Релиз' // TODO: Добавить связь проекта с релизом

    return (
        <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
                <TableRow className="pa-8">
                    <TableCell className={"project_list table_cell pa-8"} align="right">{number}</TableCell>
                    <TableCell className="pa-8">{name}</TableCell>
                    <TableCell className="pa-8">{type}</TableCell>
                    <TableCell className="pa-8">{status}</TableCell>
                    <TableCell className="pa-8">{baseline_finish}</TableCell>
                    <TableCell className="pa-8">{completed}</TableCell>
                    <TableCell className="pa-8">{release}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {projects.map((row, index) => (
                    <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell align='right' className={"project_list table_cell sm"}>{row.number}</TableCell>
                        <TableCell className="pa-8"><Button
                            href={'/project/' + row.number}>{row.name}</Button></TableCell>
                        <TableCell
                            className={row.type ? "pa-8" : "pa-8 font-line-through"}>{row.type ? row.type.name : "Тип проекта удалён"}</TableCell>
                        <TableCell className={row.status ? "pa-8" : "pa-8 font-line-through"}>
                            <StatusChange
                                activeProject={activeProject}
                                row={row}
                                index={index}
                                change_prop={change_prop}
                                setIsSelectStatusOpen={setIsSelectStatusOpen}
                                isSelectStatusOpen={isSelectStatusOpen}
                                saveUpdates={saveUpdates}
                            />
                        </TableCell>
                        <TableCell className="pa-8">{row.baseline_finish}</TableCell>
                        <TableCell className="pa-8" align="right">{row.completed * 100}{"%"}</TableCell>
                        <TableCell className="pa-8">22-3</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

const StatusChange = ({row, index, change_prop, setIsSelectStatusOpen, isSelectStatusOpen, activeProject, saveUpdates}) => {

    const statusChange = event => {
        let ap = {}
        ap.id = row.id
        ap.number = row.number
        ap[event.target.name] = event.target.value
        saveUpdates(ap, index)
        setIsSelectStatusOpen = false

    }

    if (isSelectStatusOpen && (activeProject.id ===row.id)) {
        return <FormControl fullWidth variant={"standard"}>
            <InputLabel id="status_id">Статус проекта</InputLabel>
            <Select
                labelId="status_id"
                id="status_id"
                name={'status_id'}
                label="Статус проекта"
                variant="standard"
                onChange={e => statusChange(e)}
                value={row.status_id}
            >
                {row.type.statuses_list.map(status => {
                    return <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    }
    else {
        return <Chip
            onContextMenu={e=>{change_prop(e, index, 'status')}}
            label={row.status ? row.status.name : "Статус проекта удалён"}
            color={row.status ? "primary" : "error"}
            variant="outlined"/>}
}

import * as React from "react";
import {useContext, useEffect} from "react";
import {Button, Grid, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {useOutletContext} from "react-router";
import {LayoutContext} from "../../hok/LayoutProvider";
import moment from 'moment'
import Paper from "@mui/material/Paper";
import AirplanemodeActiveOutlinedIcon from '@mui/icons-material/AirplanemodeActiveOutlined';


const MainTab = () => {
    const {metaData, changeMeta} = useContext(LayoutContext)
    const [project, activeTab, setActiveTab] = useOutletContext()


    useEffect(() => {
        changeMeta({
            mainTitle: project?.number + ". " + project?.name,
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
        setActiveTab('main')
    }, [])

    return <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2}} className="px-24">
            <Grid item xs={6}>
                <Paper elevation={3} sx={{p: 2}} component="div">
                    <div className="project_single box flex small">
                        <span>{"дата создания: " + project?.created_at_str}</span>
                        <span>{"дата обновления: " + project?.updated_at_str}</span>
                    </div>
                        <h5>Общая информация о проекте</h5>
                    <Button href={"/project/" + project.number + "/update"} ><AirplanemodeActiveOutlinedIcon className="mr-8" /></Button>
                    {project?.number && <ProjectSummaryTable projectData={project}/>}
                    <h5>Описание проекта</h5>
                    <p>{project?.description}</p>
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper elevation={3} sx={{p: 2}}>
                    <h5>График работ</h5>
                </Paper>
            </Grid>
        </Grid>
}

const ProjectSummaryTable = ({projectData}) => {

     const setToClipboard = () => {
        navigator.clipboard.writeText (projectData.project_folder)
            .then (() => {
                console.log ("Текст скопирован в буфер обмена")
            })
            .catch (err => {
                console.log ('Something went wrong', err);
            });
    }

    return (<>
        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
            <TableBody>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Название</TableCell>
                    <TableCell>{projectData.name}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Тип</TableCell>
                    <TableCell>{projectData.type.name}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}
                          className={projectData.status.is_deleted ? "status_deleted" : ""}>
                    <TableCell className="w-230">Статус</TableCell>
                    <TableCell>{projectData.status.name}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Короткое наименование</TableCell>
                    <TableCell>{projectData.shortname}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Проектная директория</TableCell>
                    <TableCell onClick={setToClipboard}>{projectData.project_folder}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">ID на доске задач</TableCell>
                    <TableCell><a href={projectData.team_project_link}
                                  target="_blank">{projectData.team_project_link}</a></TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Согласование оплаты</TableCell>
                    <TableCell>{projectData.ariba}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Дата запроса</TableCell>
                    <TableCell>{projectData.requirement_at ? moment(projectData.requirement_at).format("DD.MM.YYYY") : ""}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Плановая дата старта</TableCell>
                    <TableCell>{projectData.baseline_start ? moment(projectData.baseline_start).format("DD.MM.YYYY") : ""}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Плановая дата завершения</TableCell>
                    <TableCell>{projectData.baseline_finish ? moment(projectData.baseline_finish).format("DD.MM.YYYY") : ""}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </>)

}

export default MainTab

import Box from "@mui/material/Box";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Button, LinearProgress, Link} from "@mui/material";
import {AddCircleOutline} from "@mui/icons-material";
import {valueService} from "../../services/valueService";
import {LayoutContext} from "../../hok/LayoutProvider";
import moment from 'moment';
import lodash from 'lodash'

const ValuesList = () => {

    const {metaData, changeMeta} = useContext(LayoutContext)
    const [anchorEl, setAnchorEl] = useState(null);
    const [values, setValues] = useState(null)
    const [loading, setLoading] = useState(true)
    const [selectedValue, setSelectedValue] = useState(null)


    useEffect(() => {
        changeMeta({
            mainTitle: "Цели",
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
    }, [])

    const selectRow = i => {
        console.log("ValuesList.jsx : 36", i)
        const selectedValue = lodash.cloneDeep(values[i])
        setSelectedValue({value:selectedValue, index:i})
    }


    useEffect(() => {
        valueService.getAll().then(_ => {
            setValues(_)
            setLoading(false)
        })
    }, [])

    // Определить массив id A, у которых parent_id не заполнено
    // Определить массив id B, у которых parent_id заполнено
    // Определить массив C из массива B, у которых parent_id === id массива A

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleKeyDown = (event) => {
        if (event.altKey && event.shiftKey && event.key === "ArrowRight" && selectedValue) {
            if(values[0].id === selectedValue.value.id) setSelectedValue(null)
            else {
                const parentId = values[selectedValue.index-1].id
                valueService.updateValue({parent_id:parentId, id:selectedValue.value.id}, selectedValue.value.id).then(_ => {
                    console.log("Обновили PARENT ID", _)
                })
            }
        }
    };

    const handleKeyUp = (event) => {
        if (event.altKey && event.shiftKey && event.key === "ArrowRight") {
            //setKeyCombinationPressed(false);
        }
    };


    if (loading) {
        return <LinearProgress/>
    } else {
        // TODO: добавить ручное управление сортировкой целей
        // TODO: продумать UX планирования сроков по целям
        // TODO: продумать UX добавления целей с типами и управления статусами
        // TODO: продумать UX подсчёт объёма работ по целям (надо подумать откуда будут браться оценки)
        return (<Paper elevation={3} sx={{m: 3, p: 3}}  onKeyDown={e=>handleKeyDown(e)} onKeyUp={e=>handleKeyUp(e)}>
                    <Box justifyContent={'flex-end'} display={'flex'}>
                        <Button variant="outlined" href="/value/create" startIcon={<AddCircleOutline/>}>Новая цель</Button>
                    </Box>
                    <Box component="div">
                        {values.length > 0 ? <BasicTable
                            values={values}
                            selectRow={selectRow}
                            selectedValue={selectedValue}
                            setSelectedValue={setSelectedValue}
                        /> : <h3>Список целей пуст</h3>}
                    </Box>
                </Paper>)
    }
}

export {ValuesList}


const BasicTable = ({values, selectRow, selectedValue, setSelectedValue}) => {
    const [keyCombinationPressed, setKeyCombinationPressed] = useState(false)
    const name = 'Наименование'
    const type = 'Тип'
    const status = 'Статус'
    const baseline_finish = 'Завершение'
    const completed = 'Готовность, %'
    const release = 'Релиз'

    return (
        <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
                <TableRow className="pa-8">
                    <TableCell className="pa-8">{name}</TableCell>
                    <TableCell className="pa-8">{type}</TableCell>
                    <TableCell className="pa-8">{status}</TableCell>
                    <TableCell className="pa-8">{baseline_finish}</TableCell>
                    <TableCell className="pa-8">{completed}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {values.map((row, index) => (
                    <TableRow className={index===selectedValue?.index ? 'selected' : ""} onClick={()=>selectRow(index)} key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}} tabIndex={index}>
                        <TableCell className="pa-8"><Link href={"/value/" + row.id}>{row.name}</Link></TableCell>
                        <TableCell className="pa-8">{row.type?.name}</TableCell>
                        <TableCell className="pa-8">{row.status?.name}</TableCell>
                        <TableCell className="pa-8">{row.baseline_finish ? moment(row.baseline_finish).format("DD.MM.YYYY") : " - "}</TableCell>
                        <TableCell className="pa-8" align="right">{row.completed * 100}{"%"}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

import React, {useRef, useState}           from 'react';
import {Button, LinearProgress, TextField} from "@mui/material";
import Box                                 from "@mui/material/Box";
import AppBar                              from "@mui/material/AppBar";
import Toolbar                             from "@mui/material/Toolbar";
import Typography                          from "@mui/material/Typography";
import {formToBodySerialize}               from "../../utils";
import lodash                              from "lodash";
import {userService}                       from "../../services/userService";
import {useNavigate}                       from "react-router-dom";


const UserCreate = () => {
    const NEW_USER_TEMPLATE = {
        username: "",
        password: "",
    }
    const [loading, setLoading] = useState(false)
    const [newUser, setNewUser] = useState(NEW_USER_TEMPLATE)
    const navigate = useNavigate()
    const createUserForm = useRef()

    const changeNewUserProps = e => {
        newUser[e.target.name] = e.target.value
        setNewUser(newUser)
    }

    const createNewUser = e => {
        e.preventDefault ()
        console.log('Создаём пользователя', newUser)
            let userModel = lodash.cloneDeep(newUser)
            const formObject = formToBodySerialize(new FormData(createUserForm.current))
            userModel.username = formObject.username
            userModel.password = formObject.password
            userService.create(userModel).then (_ => {
                navigate('/config/users')
            })
    }

    if (loading) {
        return <LinearProgress/>
    }
    else {

        return (
            <Box
                component="main"
                sx={{flexGrow: 1, bgcolor: 'background.default', p: 0, mb: 2}}
            >
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>Создание пользователя</Typography>
                    </Toolbar>
                </AppBar>
                <CreateNewUserForm changeNewUserProps={changeNewUserProps} newUser={newUser} createNewUser={createNewUser} createUserForm={createUserForm}/>
            </Box>
        );
    }
};

export default UserCreate;

const CreateNewUserForm = ({newUser, createNewUser, changeNewUserProps, createUserForm}) => {

    return (<Box
        component="form"
        noValidate
        autoComplete="off"
        className="pa-24"
        onSubmit={createNewUser}
        ref={createUserForm}
    >
        <div>
            <TextField
                id="outlined-required"
                name="username"
                autoFocus
                label="Логин"
                variant="standard"
                className="w100"
                onChange={changeNewUserProps}
                defaultValue={newUser.username}
            />
            <TextField
                id="outlined-required"
                name="password"
                defaultValue={newUser.password}
                onChange={changeNewUserProps}
                label="Пароль"
                variant="standard"
                className="w100 mt-24"
            />
            <div className="flex content-between">
                <Button variant="outlined" color='primary' className="mt-24" onClick={createNewUser}>{"Создать пользователя"}</Button>
            </div>
        </div>
    </Box>)
}
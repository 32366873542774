import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {staffService} from "../../services/staffService";
import {Button, LinearProgress, Link} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {AddCircleOutline} from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import {LayoutContext} from "../../hok/LayoutProvider";


const StaffList = () => {

    const [staff, setStaff] = useState(null)
    const [loading, setLoading] = useState(true)
    const {metaData, changeMeta} = useContext(LayoutContext)

    useEffect(() => {
        staffService.getAll().then(_ => {
            setStaff(_)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        changeMeta({
            mainTitle: "Список сотрудников",
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
    }, [])

    if (loading) {
        return <LinearProgress/>
    } else return (<>
            <Paper elevation={3} sx={{m: 3, p: 3}}>
                <Box justifyContent={'flex-end'} display={'flex'}>
                    <Button variant="outlined" href="/person/create" startIcon={<AddCircleOutline/>}>
                        Добавить нового сотрудника
                    </Button>
                </Box>
                <Box component="div">
                    {staff.length > 0 && <BasicTable staff={staff}/>}
                </Box>
            </Paper>
        </>
    )
}

export {StaffList}

const BasicTable = ({staff}) => {
    const number = '№'
    const fullName = 'Имя Фамилия'
    const email = 'Email'
    const phone = 'Телефон'
    const grade = 'Грейд'
    const profile_lnk = 'Ссылка на профиль'
    const active = 'Статус'
    const line_manger = 'Линейный менеджер'
    const birthday = 'ДР'

    return (
        <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
                <TableRow className="pa-8">
                    <TableCell className={"project_list table_cell pa-8"} align="right">{number}</TableCell>
                    <TableCell className="pa-8">{fullName}</TableCell>
                    <TableCell className="pa-8">{email}</TableCell>
                    <TableCell className="pa-8">{phone}</TableCell>
                    <TableCell className="pa-8 w-210">{grade}</TableCell>
                    <TableCell className="pa-8">{active}</TableCell>
                    <TableCell className="pa-8">{line_manger}</TableCell>
                    <TableCell className="pa-8">{birthday}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {staff.map(row => (
                    <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell align='right' className={"project_list table_cell sm"}>{row.id}</TableCell>
                        <TableCell className="pa-8"><Link to={"/person/" + row.id}
                                                          href="src/components/staff/StaffList">{row.name + " " + row.familyname}</Link></TableCell>
                        <TableCell className="pa-8">{row.email}</TableCell>
                        <TableCell className="pa-8">{row.phone}</TableCell>
                        <TableCell className="pa-8 w-210">{"Будет грейд"}</TableCell>
                        <TableCell className="pa-8">{row.is_active ? "Активный" : "Неактивный"}</TableCell>
                        <TableCell className="pa-8">{row.line_manger}</TableCell>
                        <TableCell className="pa-8">{row.birthday}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
import React, {useContext, useEffect, useState} from 'react';
import {LinearProgress, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {staffService} from "../../services/staffService";
import {useParams} from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {LayoutContext} from "../../hok/LayoutProvider";


const PersonSingle = () => {
    const [loading, setLoading] = useState(true)
    const [person, setPerson] = useState(true)
    const {id} = useParams()
    const {metaData, changeMeta} = useContext(LayoutContext)


    useEffect(() => {
        staffService.get_by_id(id).then(_ => {
            setPerson(_)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        changeMeta({
            mainTitle: person?.familyname + " " + person?.name,
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
    }, [person])


    if (loading) {
        return <LinearProgress/>
    } else return (
        <>
            <Paper elevation={3} sx={{m: 3, p: 3}}>
                <Box component="div">
                    {person && <PersonSummaryTable person={person}/>}
                </Box>
            </Paper>
        </>
    );
};

export default PersonSingle;

const PersonSummaryTable = ({person}) => {

    const setToClipboard = () => {
        navigator.clipboard.writeText(person.project_folder)
            .then(() => {
                console.log("Текст скопирован в буфер обмена")
            })
            .catch(err => {
                console.log('Something went wrong', err);
            });
    }

    return (<>
        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
            <TableBody>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Имя</TableCell>
                    <TableCell>{person.name}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Фамилия</TableCell>
                    <TableCell>{person.familyname}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Грейд</TableCell>
                    <TableCell onClick={setToClipboard}>{person.project_folder}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}
                          className={person.is_deleted ? "status_deleted" : ""}>
                    <TableCell className="w-230">Статус</TableCell>
                    <TableCell>{person.is_deleted ? "Помечен на удаление" : "Активен"}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Краткое имя</TableCell>
                    <TableCell>{person.short_name}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Email</TableCell>
                    <TableCell>{person.email}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Телефон</TableCell>
                    <TableCell>{person.phone}</TableCell>
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell className="w-230">Telegram</TableCell>
                    <TableCell><a href={"https://t.me/" + person.telegram_id}
                                  target="_blank">{"@" + person.telegram_id}</a></TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </>)

}
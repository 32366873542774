import React, {useContext, useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import {NavLink, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Collapse, IconButton, ListItemButton, Menu, MenuItem} from "@mui/material";
import {AccountCircle, ExpandLess, ExpandMore} from "@mui/icons-material";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import MenuIcon from '@mui/icons-material/Menu';
import {AuthContext} from "../../hok/AuthProvider";
import {LayoutContext} from "../../hok/LayoutProvider";
import CrisisAlertOutlinedIcon from '@mui/icons-material/CrisisAlertOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

const drawerWidth = 240;

export const Layout = () => {

    const [open, setOpen] = useState(false);
    const location = useLocation()
    const handleClick = () => {
        setOpen(!open);
    };
    const navigate = useNavigate()
    const {user, singIn, signOut} = useContext(AuthContext)
    const {metaData, changeMeta} = useContext(LayoutContext)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isActive = ({isActive}) => isActive ? 'nav-link-active' : 'nav-link'

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar/>
                <Divider/>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <NavLink to='/'
                             className={isActive}><ListItem
                        style={{minWidth: "36px"}}><ListItemIcon><BusinessCenterIcon/></ListItemIcon><ListItemText
                        primary="Проекты"/></ListItem></NavLink>
                    <NavLink to='/values'
                             className={isActive}><ListItem><ListItemIcon><CrisisAlertOutlinedIcon/></ListItemIcon><ListItemText
                        primary="Цели"/></ListItem></NavLink>
                    <NavLink to='/activities'
                             className={isActive}><ListItem><ListItemIcon><PlaylistAddCheckOutlinedIcon/></ListItemIcon><ListItemText
                        primary={'Активности'}/></ListItem></NavLink>
                    <NavLink to='/releases'
                             className={isActive}><ListItem><ListItemIcon><FlightTakeoffOutlinedIcon/></ListItemIcon><ListItemText
                        primary={'Релизы'}/></ListItem></NavLink>
                    <NavLink to='/pnl'
                             className={isActive}><ListItem><ListItemIcon><SavingsOutlinedIcon/></ListItemIcon><ListItemText
                        primary={'Финансы'}/></ListItem></NavLink>
                    <NavLink to='/staff'
                             className={isActive}><ListItem><ListItemIcon><Diversity3OutlinedIcon/></ListItemIcon><ListItemText
                        primary={'Команда'}/></ListItem></NavLink>
                    <NavLink to='/assignment'
                             className={isActive}><ListItem><ListItemIcon><AssignmentIndOutlinedIcon/></ListItemIcon><ListItemText
                        primary={'Назначенность'}/></ListItem></NavLink>


                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <ViewListOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Справочники"/>
                        {open ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <NavLink to='config/dictionaries/status' className={isActive}><ListItem
                                sx={{pl: 4}}><ListItemText sx={{fontSize: '0.8rem'}} primary="Статусы"/>
                            </ListItem>
                            </NavLink>
                        </List>
                        <List component="div" disablePadding>
                            <NavLink to='config/dictionaries/type' className={isActive}><ListItem
                                sx={{pl: 4}}><ListItemText sx={{fontSize: '0.8rem'}} primary="Типы проектов"/>
                            </ListItem>
                            </NavLink>
                        </List>
                        <List component="div" disablePadding>
                            <NavLink to='config/users' className={isActive}><ListItem sx={{pl: 4}}><ListItemText
                                sx={{fontSize: '0.8rem'}} primary="Системные пользователи"/>
                            </ListItem>
                            </NavLink>
                        </List>
                    </Collapse>
                    <NavLink to='/help'
                             className={isActive}><ListItem><ListItemIcon><HelpOutlineRoundedIcon/></ListItemIcon><ListItemText
                        primary={'Помощь'}/></ListItem></NavLink>
                </List>
                <Divider/>
            </Drawer>
            <Box
                component="main"
                sx={{flexGrow: 1, bgcolor: 'background.default', p: 0, mb: 2}}
            >
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 2}}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            {metaData.mainTitle}
                        </Typography>
                        <div>
                            {user?.username}
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose}>Profile</MenuItem>
                                <MenuItem onClick={()=>signOut(()=>{navigate('/')})}>Выйти</MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
                <Outlet/>
            </Box>
        </Box>
    )
}
import * as React from "react";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Autocomplete, Button, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField} from "@mui/material";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import lodash from "lodash";
import {projectsService} from "../../services/projectsService";
import {valueService} from "../../services/valueService";
import {typeService} from "../../services/typeService";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {getNormalDate} from "../../helpers/functions";
import {formToBodySerialize} from "../../utils";
import {useNavigate} from "react-router-dom";
import {LayoutContext} from "../../hok/LayoutProvider";


const ValueCreate = () => {

    const {metaData, changeMeta} = useContext(LayoutContext)
    const [selectedValueIndex, setSelectedValueIndex] = useState(null)
    const [newValue, setNewValue] = useState(null)
    const [anchorEl, setAnchorEl] = useState()
    const [listValue, setListValue] = useState([])
    const [isloading, setIsloading] = useState()
    const [newValueName, setNewValueName] = useState("")


    const [selectedProject, setSelectedProject] = useState(null)
    const [projectsList, setProjectsList] = useState([])
    const [selectedTypeIndex, setSelectedTypeIndex] = useState(null)
    const [valueTypeList, setValueTypeList] = useState([])
    const [statusesList, setStatusesList] = useState([])
    const [valueStatusList, setValueStatusList] = useState([])


    const emptyValue = {
        parent_id: null,
        project_id: null,
        name: "",
        eng_name: "",
        description: "",
        completed: 0.0,
        size: 0,
        remaining: 0,
        status_id: null,
        type_id: null,
        priority_id: null,
        critical_id: null,
        project_position: null,
        all_values_position: null,
        entity_values_position: null,
        deadline: null,
        baseline_finish: null,
        baseline_start: null,
        start: null,
        finish: null,
        release_id: null,
    }
    const formValue = useRef()
    const navigate = useNavigate()

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        changeMeta({
            mainTitle: "Создание целей",
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
    }, [])


    const selectValue = (i) => {
        if ( listValue[i].type_id > 0) {
            getStatusesList(listValue[i].type_id)
        }
        setSelectedValueIndex(i)
    }

    const changeValueProperty = e => {
        if (e.target.name === "type_id") getStatusesList(e.target.value)

        // Глубокое копируем шаблонный список целей
        let newlv = lodash.cloneDeep(listValue)

        switch (e.target.name) {
            case 'size': {
                const size = Number(e.target.value)
                    let remaining = listValue[selectedValueIndex].remaining
                    let c = 1
                if (size !== 0) {
                    c = Number (1-(remaining/size))
                }
                else break
                newlv[selectedValueIndex].size = size
                newlv[selectedValueIndex].completed = c.toFixed(2)
                break
            }
            case 'remaining': {
                const remaining = Number(e.target.value)
                    let size = listValue[selectedValueIndex].size
                    let c = 1
                if (size !== 0) {
                    c = Number(1-(remaining/size))
                }
                else break
                newlv[selectedValueIndex].remaining = remaining
                newlv[selectedValueIndex].completed = Number(c.toFixed(2))
                break
            }
            case 'completed': {
                const completed = Number(e.target.value)
                let size = listValue[selectedValueIndex].size
                const remainig = 1 - (completed * size)
                newlv[selectedValueIndex].remaining = remainig.toFixed(0)
                break
            }

            default: {
                // ловим изменённое в форме свойство и присваиваем его элементу массива целей, который выбран и индекс, которого сохранён в переменную selectedValueIndex
                newlv[selectedValueIndex][e.target.name] = e.target.value
                // Пересохраняем новый список целей
            }
        }
        setListValue(newlv)
    }
    const completedCount = work => {

    }
    const changeValueDates = (date, propName) => {
        let newlv = lodash.cloneDeep(listValue)

        // ловим изменённое в форме свойство и присваиваем его элементу массива целей, который выбран и индекс, которого сохранён в переменную selectedValueIndex
        newlv[selectedValueIndex][propName] = date
        // Пересохраняем новый список целей

        if (propName === "baseline_start") {
            newlv[selectedValueIndex]["start"] = date
        }
        if (propName === "baseline_finish") {
            newlv[selectedValueIndex]["finish"] = date
            newlv[selectedValueIndex]["deadline"] = date
        }


        setListValue(newlv)
    }

    const getStatusesList = typeId => {
        if (listValue.length > 0) {
            valueTypeList.forEach(type => {
                if (type.id == typeId && Array.isArray(type.statuses_list)) {
                    setStatusesList(type.statuses_list)
                }
            })
        }
    }

    const addValue = e => {
        if ((e.code === "Enter" || e.code === "NumpadEnter") && e.target.value !== "") {
            let v = lodash.cloneDeep(emptyValue)
            v.name = newValueName
            v.project_id = selectedProject ? selectedProject.id : null
            listValue.push(v)
            setListValue(listValue)
            setNewValueName("")
        } else return false
    }

    // Приходит массив целей
    // Отображаем в списке
    // Выбираем цель для редактирования
    // Редактируем
    // Отправляем отредактированную цель на сервер
    //
    // Если хотим добавить новую цель
    // Добавляем новую цель
    // Редактируем новую цель
    // Сохраняем на сервере
    // Обновляем массив целей


    const saveValueToServer = e => {
        let currentValue = listValue[selectedValueIndex]
            e.preventDefault ();
            let v = formToBodySerialize (new FormData (formValue.current))
            currentValue.project_id = selectedProject.id
            v.baseline_start === "" ? delete v.baseline_start : currentValue.baseline_start = getNormalDate (v.baseline_start)
            v.start === "" ? delete v.start : currentValue.start = getNormalDate (v.start)
            v.baseline_finish === "" ? delete v.baseline_finish : currentValue.baseline_finish = getNormalDate (v.baseline_finish)
            v.finish === "" ? delete v.finish : currentValue.finish = getNormalDate (v.finish)
            v.deadline === "" ? delete v.deadline : currentValue.deadline = getNormalDate (v.deadline)
            currentValue.project_position = 3
            currentValue.all_values_position = 3
            currentValue.entity_values_position = 3
            console.log('New Value', currentValue)
            const body = JSON.stringify (currentValue)

        if (currentValue.hasOwnProperty('id')) {
            valueService.updateValue(body, currentValue.id).then(_ => {
                setListValue(_)
            })
        }
        else {
            valueService.createValue(body).then(_ => {
                setListValue(_)
            })
        }
    }

    useEffect(() => {
        projectsService.getAll().then(_ => {
            setProjectsList(_)
        })
    }, [])

    useMemo(() => {
        typeService.getByEntity("value").then(_ => {
            console.log('TypeList:', _)
            setValueTypeList(_)
        })
    }, [])

    const selectProjectFunction = (e, selectedProject) => {
        setIsloading(true)
        valueService.get_values_by_project_id(selectedProject.id).then(_ => {
            setListValue(_)
            setIsloading(false)
        })
        setSelectedProject(selectedProject)
    }

    const ListValue = ({lv}) => {
        if (lv.length > 0) {
            return lv.map((v, i, arr) => {
                return (<div className={selectedValueIndex === i ? "valueListItem selected_value" : "valueListItem"} key={i}
                             onClick={() => selectValue(i)}>
                    <span className="name">{i + 1 + ". " + v.name}</span>
                    <span className="small_text">{v.description}</span>
                </div>)
            })
        } else return <h5>Проект не имеет целей</h5>
    }

    if (projectsList.length > 0) {

        return (<>
                <Grid container rowSpacing={1} p={3} columnSpacing={{xs: 1, sm: 2}}>
                    <Grid item md={12}>
                        <Paper elevation={3} sx={{p: 2}}>
                            <Box component="div" className="select_box">
                                <ProjectSelect selectProjectFunction={selectProjectFunction}
                                               selectedProject={selectedProject} projectsList={projectsList}/>
                            </Box>
                            {selectedProject && <Box component='div' className="mt-16">
                                <TextField autoFocus fullWidth value={newValueName}
                                           onChange={e => setNewValueName(e.target.value)} onKeyDown={addValue}
                                           variant="standard"
                                           label="Добавить новую цель"/>
                            </Box>}
                        </Paper>
                    </Grid>
                    <Grid item md={6}>
                        <Paper elevation={3} className="pa-16 mt-16">
                            <Box component="div" className="list_of_new_value">
                                <ListValue selectValue={selectValue} lv={listValue}/>
                            </Box>
                        </Paper>
                    </Grid>
                    {selectedValueIndex !== null && listValue.length >0 && <Grid item md={6}>
                        <Paper elevation={3} className="pa-16 mt-16">
                            <FormValue formValue={formValue}
                                       selectedValue={listValue[selectedValueIndex]}
                                       emptyValue={emptyValue}
                                       changeValueProperty={changeValueProperty}
                                       valueTypeList={valueTypeList}
                                       statusesList={statusesList}
                                       changeValueDates={changeValueDates}
                                       saveValueToServer={saveValueToServer}
                                       setNewValue={setNewValue}
                            />
                        </Paper>
                    </Grid>}
                </Grid>
        </>)
    } else return <LinearProgress/>
}
export default ValueCreate


const ProjectSelect = ({selectProjectFunction, selectedProject, projectsList}) => {
    return (
        <div>
            <Autocomplete
                onChange={(e, selectedProject) => selectProjectFunction(e, selectedProject)}
                disablePortal
                fullWidth
                id="combo-box-demo"
                options={projectsList}
                getOptionLabel={p => p.number + ". " + p.name}
                renderInput={(params) => <TextField {...params} label="Выберите проект" variant="standard"/>}
            />
        </div>
    );
}

const FormValue = ({
                       formValue,
                       valueTypeList,
                       selectedValue,
                       changeValueProperty,
                       statusesList,
                       changeValueDates,
                       saveValueToServer,
                   }) => {

    // parent_id      : null,
    // project_id     : null,
    // name           : "",
    // eng_name       : "",
    // description    : "",
    // completed      : 0.0,

    // value_status_id: null,
    // value_type_id  : null,

    // priority_id    : null,
    // critical_id    : null,

    // deadline       : null,

    // baseline_finish: null,
    // baseline_start : null,
    // start          : null,
    // finish         : null,
    // release_id     : null,

    return (<>
        <Box component="form" ref={formValue}>
            <Grid container rowSpacing={1} p={3} columnSpacing={{xs: 1, sm: 2}}>
                <Grid item md={12}>
                    <TextField autoFocus fullWidth
                               name='name'
                               value={selectedValue.name}
                               onChange={changeValueProperty}
                               variant="standard"
                               label="Название цели"/>
                </Grid>
                <Grid item md={6} mt={3}>
                    <FormControl variant="standard" sx={{minWidth: "220px"}} fullWidth>
                        <InputLabel id="demo-simple-select-standard-label">Тип</InputLabel>
                        <Select
                            value={selectedValue.type_id ? selectedValue.type_id : ''}
                            name="type_id"
                            onChange={changeValueProperty}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="Тип цели"
                        >
                            <MenuItem value="">
                                <em>Не определен</em>
                            </MenuItem>
                            {valueTypeList.length > 0 ? valueTypeList.map(vt => <MenuItem key={vt.id} value={vt.id}>{vt.name}</MenuItem>) : null}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={6} mt={3}>
                    <FormControl variant="standard" sx={{minWidth: "220px"}} fullWidth>
                        <InputLabel id="demo-simple-select-standard-label">Статус</InputLabel>
                        <Select
                            value={selectedValue.status_id ? selectedValue.status_id : ''}
                            name="status_id"
                            onChange={changeValueProperty}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="Статус цели"
                        >
                            <MenuItem value="">
                                <em>Не определен</em>
                            </MenuItem>
                            {statusesList.length > 0 ? statusesList.map(s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>) : null}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} p={3} columnSpacing={{xs: 1, sm: 2}}>
                <Grid item md={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label={"Плановая дата старта"}
                            value={selectedValue.baseline_start}
                            inputFormat='DD.MM.YY'
                            onChange={(newValue) => changeValueDates(newValue, 'baseline_start')}
                            renderInput={(params) => <TextField name="baseline_start" {...params} sx={{width: "100%"}}/>}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label={"Плановая дата завершения"}
                            value={selectedValue.baseline_finish}
                            inputFormat='DD.MM.YY'
                            onChange={(newValue) => changeValueDates(newValue, 'baseline_finish')}
                            renderInput={(params) => <TextField name="baseline_finish" {...params} sx={{width: "100%"}}/>}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} p={3} columnSpacing={{xs: 1, sm: 2}}>
                <Grid item md={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label={"Дата старта"}
                            value={selectedValue.start}
                            inputFormat='DD.MM.YY'
                            onChange={(newValue) => changeValueDates(newValue, 'start')}
                            renderInput={(params) => <TextField name="start" {...params} sx={{width: "100%"}}/>}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label={"Завершение"}
                            value={selectedValue.finish}
                            inputFormat='DD.MM.YY'
                            onChange={(newValue) => changeValueDates(newValue, 'finish')}
                            renderInput={(params) => <TextField name="finish" {...params} sx={{width: "100%"}}/>}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label={"Дедлайн"}
                            value={selectedValue.deadline}
                            inputFormat='DD.MM.YY'
                            onChange={(newValue) => changeValueDates(newValue, 'deadline')}
                            renderInput={(params) => <TextField name="deadline" {...params} sx={{width: "100%"}}/>}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} p={3} columnSpacing={{xs: 1, sm: 2}}>
                <Grid item md={4}>
                    <TextField autoFocus fullWidth
                               name='size'
                               value={selectedValue.size}
                               onChange={changeValueProperty}
                               variant="standard"
                               type='number'
                               label="Объём работ"/>
                </Grid>
                <Grid item md={4}>
                    <TextField autoFocus fullWidth
                               name='remaining'
                               value={selectedValue.remaining}
                               onChange={changeValueProperty}
                               variant="standard"
                               type='number'
                               label="Требуется"/>
                </Grid>
                <Grid item md={4}>
                    <TextField autoFocus fullWidth
                               name='completed'
                               value={selectedValue.completed}
                               onChange={changeValueProperty}
                               type='number'
                               variant="standard"
                               label="Готовность"/>
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} p={3} columnSpacing={{xs: 1, sm: 2}}>
                <Grid item md={12} display='flex' justifyContent='end'>
                    <Button variant='outlined' color='success' onClick={e => saveValueToServer(e)} startIcon={<SaveOutlinedIcon />}>Сохранить</Button>
                </Grid>
            </Grid>
        </Box>
    </>)
}
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {Outlet} from "react-router-dom";
import * as React from "react";

const ActivitiesPage = () => {


    return (<>
            <Box
                component="main"
                sx={{flexGrow: 1, bgcolor: 'background.default', p: 3}}
            >
                <AppBar
                    position="fixed"
                    sx={{width: `calc(100% - ${240}px)`, ml: `${240}px`}}
                >
                    <Toolbar>
                        <Typography variant="h6" noWrap component="div">Управление активностями</Typography>
                    </Toolbar>
                </AppBar>
                <Outlet/>
            </Box>
        </>
    )
}

export {ActivitiesPage}
import Box from "@mui/material/Box";
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";

import Paper from '@mui/material/Paper';
import {useNavigate} from "react-router-dom";
import {Button, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField} from "@mui/material";
import {projectsService} from "../../services/projectsService";
import {typeService} from "../../services/typeService";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {formToBodySerialize} from "../../utils";
import {getNormalDate} from "../../helpers/functions";
import {releaseService} from "../../services/releaaseService";
import {LayoutContext} from "../../hok/LayoutProvider";


const ProjectCreate = () => {

    const [project, setProject] = useState({
        number: '',
        name: '',
        description: '',
        project_status_id: '',
        project_type_id: '',
        entity: 'project',

        shortname: '',
        engname: '',
        team_project_link: '',
        project_folder: '',
        release_id: '',
        completed: 0.0,

        baseline_start: null,
        baseline_finish: null,
        start: null,
        finish: null,
        requirement_at: null,
    })
    const [typeId, setTypeId] = useState(null)
    const [listProjectStatus, setListProjectStatus] = useState([])
    const [listProjectType, setListProjectType] = useState([])
    const [listReleases, setListReleases] = useState([])
    const [emptyNumbers, setEmptyNumbers] = useState([])
    const [loading, setLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(false)
    const [baseline_start, setBaseline_start] = useState(null)
    const [baseline_finish, setBaseline_finish] = useState(null)
    const [start, setStart] = useState(null)
    const [finish, setFinish] = useState(null)
    const [requirement_at, setRequirement_at] = useState(null)

    const navigate = useNavigate()
    const projectForm = useRef()


    const {metaData, changeMeta} = useContext(LayoutContext)

    useEffect(() => {
        changeMeta({
            mainTitle: "Создание нового проекта",
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
    }, [])


    useEffect(() => {
        projectsService.getEmptyNumbers().then(_ => {
            setEmptyNumbers(_)
            project.number = _[_.length - 1]
            setProject({})
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        typeService.getByEntity('project').then(_ => {
            setListProjectType(_)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        releaseService.getAll().then(_ => {
            setListReleases(_)
        })
    }, [])


    useEffect(() => {
        if (project.project_type_id > 0) {
            const type = listProjectType.find(item => item.id == project.project_type_id)
            setListProjectStatus(type.statuses_list)
        }
    }, [project.project_type_id, project.project_status_id])


    const onSubmitForm = (e) => {
        e.preventDefault();
        let obj = formToBodySerialize(new FormData(projectForm.current))
        obj.baseline_start === "" ? delete obj.baseline_start : obj.baseline_start = getNormalDate(obj.baseline_start)
        obj.start === "" ? delete obj.start : obj.start = getNormalDate(obj.start)
        obj.baseline_finish === "" ? delete obj.baseline_finish : obj.baseline_finish = getNormalDate(obj.baseline_finish)
        obj.finish === "" ? delete obj.finish : obj.finish = getNormalDate(obj.finish)
        obj.requirement_at === "" ? delete obj.requirement_at : obj.requirement_at = getNormalDate(obj.requirement_at)
        const body = JSON.stringify(obj)
        projectsService.createProject(body).then(_ => {
            navigate(`/project/${_.number}/main`);

        })
    };

    //TODO: Необходимо валидировать данные пользователя и раскаладывать циферки, если нет данных, то поля в модели проекта не должно быть, тогда Pydantic сам поймёт что ему делать.
    // А так он думает, что поле есть, просто не правильные данные и выдаёт ошибку.
    // При этом также если есть ошибка от Pydantic научиться её принимать и обрабатывать и оставлять пользователя на странице ввода информации.


    const handleChange = event => {
        setLoading(true)
        project[event.target.name] = event.target.value
        setProject(project)
        if (event.target.name == 'project_type_id') {
            const type = listProjectType.find(item => item.id == event.target.value)
            setListProjectStatus(type.statuses_list)
        }
        setLoading(false)
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (loading) {
        return <LinearProgress/>
    } else return <Grid container rowSpacing={2} p={2} columnSpacing={{xs: 1, sm: 2}}>
        <Grid item md={6}>
            <Paper elevation={3} sx={{p: 2, m: 3}}>
                <Box
                    component="form"
                    ref={projectForm}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container rowSpacing={3} p={2} columnSpacing={{xs: 1, sm: 2}}>
                        <Grid item md={4}>
                            <TextField
                                autoFocus
                                fullWidth
                                label={"Свободный номер проекта (" + emptyNumbers[emptyNumbers.length - 1] + ")"}
                                name={"number"}
                                type="text"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item md={4}>
                            <FormControl fullWidth variant={"standard"}>
                                <InputLabel id="project_type_id">Тип проекта</InputLabel>
                                <Select
                                    labelId="project_type_input_id"
                                    id="project_type_id"
                                    label="Тип проекта"
                                    name={'project_type_id'}
                                    onChange={e => handleChange(e)}
                                    defaultValue={''}
                                >
                                    {listProjectType.map(type => {
                                        return <MenuItem key={type.id}
                                                         value={type.id}>{type.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl fullWidth variant={"standard"}>
                                <InputLabel id="project_status_id">Статус проекта</InputLabel>
                                <Select
                                    labelId="project_status_id"
                                    id="project_status_id"
                                    name={'project_status_id'}
                                    label="Статус проекта"
                                    variant="standard"
                                    onChange={e => handleChange(e)}
                                    defaultValue={''}
                                >
                                    {listProjectStatus.map(status => {
                                        return <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                fullWidth
                                id="name"
                                label="Наименование"
                                name={'name'}
                                type="text"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                fullWidth
                                id="name"
                                label="Внутреннее короткое название"
                                name={'shortname'}
                                type="text"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                fullWidth
                                id="name"
                                label="Внутреннее короткое название на английском языке"
                                name={'engname'}
                                type="text"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                fullWidth
                                multiline
                                name={'description'}
                                rows={3}
                                label="Описание"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item md={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    value={baseline_start}
                                    label={"Плановая дата старта"}
                                    inputFormat='DD.MM.YY'
                                    onChange={(newValue) => {
                                        setBaseline_start(newValue)
                                    }}
                                    renderInput={(params) => <TextField
                                        name="baseline_start" {...params} sx={{width: "100%"}}/>}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={"Плановая дата завершения"}
                                    value={baseline_finish}
                                    inputFormat='DD.MM.YY'
                                    onChange={(newValue) => {
                                        setBaseline_finish(newValue)
                                    }}
                                    renderInput={(params) => <TextField
                                        name="baseline_finish" {...params} sx={{width: "100%"}}/>}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    value={start}
                                    label={"Фактическая дата старта"}
                                    inputFormat='DD.MM.YY'
                                    onChange={(newValue) => {
                                        setStart(newValue)
                                    }}
                                    renderInput={(params) => <TextField name="start" {...params}
                                                                        sx={{width: "100%"}}/>}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={"Фактическая дата завершения"}
                                    value={finish}
                                    inputFormat='DD.MM.YY'
                                    onChange={(newValue) => {
                                        setFinish(newValue)
                                    }}
                                    renderInput={(params) => <TextField name="finish" {...params}
                                                                        sx={{width: "100%"}}/>}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={"Дата запроса"}
                                    value={requirement_at}
                                    inputFormat='DD.MM.YY'
                                    onChange={(newValue) => {
                                        setRequirement_at(newValue)
                                    }}
                                    renderInput={(params) => <TextField
                                        name="requirement_at" {...params} sx={{width: "100%"}}/>}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl fullWidth variant={"standard"}>
                                <InputLabel id="label_release_id">Релиз</InputLabel>
                                <Select
                                    labelId="label_release_id"
                                    id="release_id"
                                    label="Тип проекта"
                                    name='release_id'
                                    onChange={e => handleChange(e)}
                                    defaultValue={''}
                                >
                                    {listReleases.map(r => {
                                        return <MenuItem key={r.id}
                                                         value={r.id}>{r.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <Button onClick={e => {
                                onSubmitForm(e)
                            }}>Создать проект</Button>
                        </Grid>

                    </Grid>
                </Box>
            </Paper>
        </Grid>
    </Grid>
}

export {ProjectCreate}


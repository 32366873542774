import React, {useEffect, useState} from 'react';
import {releaseService} from "../../services/releaaseService";
import {LinearProgress} from "@mui/material";
import {useParams} from "react-router-dom";

function ReleaseDetails(props) {
    const [loading, setLoading] = useState(true)
    const [rList, setRList] = useState([])
    const params = useParams()

    useEffect(()=> {
        releaseService.get_by_id()
            .then(_ => {
                setRList(_)
                setLoading(false)
            })
            .catch(e => console.log("ERROR", e))
    }, [])

    if (!loading)

    return (<>
        <h1>wer</h1>>
    </>);

    else return <LinearProgress />
}

export default ReleaseDetails;
import Box from "@mui/material/Box";
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";

import Paper from '@mui/material/Paper';
import {useNavigate, useParams} from "react-router-dom";
import {Button, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField} from "@mui/material";
import {projectsService} from "../../services/projectsService";
import {typeService} from "../../services/typeService";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {formToBodySerialize} from "../../utils";
import {getNormalDate} from "../../helpers/functions";
import {releaseService} from "../../services/releaaseService";
import {LayoutContext} from "../../hok/LayoutProvider";
import moment from "moment";


const ProjectUpdate = () => {

    const {projectNumber} = useParams()

    const [project, setProject] = useState({
        number: '',
        name: '',
        description: '',
        status_id: '',
        type_id: '',
        entity: 'project',

        shortname: '',
        engname: '',
        team_project_link: '',
        project_folder: '',
        release_id: '',
        completed: 0.0,

        baseline_start: null,
        baseline_finish: null,
        start: null,
        finish: null,
        requirement_at: null,
    })
    const [typeId, setTypeId] = useState(null)
    const [listProjectStatus, setListProjectStatus] = useState([])
    const [listProjectType, setListProjectType] = useState([])
    const [listReleases, setListReleases] = useState([])
    const [emptyNumbers, setEmptyNumbers] = useState([])
    const [loading, setLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(false)
    const [baseline_start, setBaseline_start] = useState(null)
    const [baseline_finish, setBaseline_finish] = useState(null)
    const [start, setStart] = useState(null)
    const [finish, setFinish] = useState(null)
    const [requirement_at, setRequirement_at] = useState(null)

    const navigate = useNavigate()
    const projectForm = useRef()


    const {metaData, changeMeta} = useContext(LayoutContext)

    useEffect(() => {
        changeMeta({
            mainTitle: "Редактирование проекта: " + projectNumber,
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
    }, [])


    useEffect(() => {
        projectsService.getByNumber(projectNumber).then(_ => {
            console.log("ProjectUpdate.jsx : 76", _)
            setBaseline_start(moment(_.baseline_start, 'YYYY-MM-DD'))
            setBaseline_finish(moment(_.baseline_finish, 'YYYY-MM-DD'))
            setStart(moment(_.start, 'YYYY-MM-DD'))
            setFinish(moment(_.finish, 'YYYY-MM-DD'))
            setRequirement_at(moment(_.requirement_at, 'YYYY-MM-DD'))
            setProject(_)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        typeService.getByEntity('project').then(_ => {
            setListProjectType(_)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        releaseService.getAll().then(_ => {
            setListReleases(_)
        })
    }, [])


    useEffect(() => {
        if (project.type_id > 0) {
            const type = listProjectType.find(item => item.id == project.type_id)
            setListProjectStatus(type?.statuses_list)
        }
    }, [project.type_id, project.status_id, listProjectStatus])


    const onSubmitForm = (e) => {
        e.preventDefault();
        let obj = formToBodySerialize(new FormData(projectForm.current))
        obj.baseline_start === "" ? delete obj.baseline_start : obj.baseline_start = getNormalDate(obj.baseline_start)
        obj.start === "" ? delete obj.start : obj.start = getNormalDate(obj.start)
        obj.baseline_finish === "" ? delete obj.baseline_finish : obj.baseline_finish = getNormalDate(obj.baseline_finish)
        obj.finish === "" ? delete obj.finish : obj.finish = getNormalDate(obj.finish)
        obj.requirement_at === "" ? delete obj.requirement_at : obj.requirement_at = getNormalDate(obj.requirement_at)
        const body = JSON.stringify(obj)
        projectsService.createProject(body).then(_ => {
            navigate(`/project/${_.number}/main`);

        })
    };

    //TODO: Необходимо валидировать данные пользователя и раскаладывать циферки, если нет данных, то поля в модели проекта не должно быть, тогда Pydantic сам поймёт что ему делать.
    // А так он думает, что поле есть, просто не правильные данные и выдаёт ошибку.
    // При этом также если есть ошибка от Pydantic научиться её принимать и обрабатывать и оставлять пользователя на странице ввода информации.


    const handleChange = event => {
        setLoading(true)
        project[event.target.name] = event.target.value
        setProject(project)
        if (event.target.name == 'type_id') {
            const type = listProjectType.find(item => item.id == event.target.value)
            setListProjectStatus(type.statuses_list)
        }
        setLoading(false)
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    if (loading && !project?.number) {
        console.log("Отсутствует проект", listProjectStatus)
        return <LinearProgress/>
    } 
    else
    {   console.log("Рендер компонента", baseline_start, listProjectStatus)
        return <Grid container rowSpacing={2} p={2} columnSpacing={{xs: 1, sm: 2}}>
            <Grid item md={6}>
                <Paper elevation={3} sx={{p: 2, m: 3}}>
                    <Box
                        component="form"
                        ref={projectForm}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container rowSpacing={3} p={2} columnSpacing={{xs: 1, sm: 2}}>
                            <Grid item md={4}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    label={"Свободный номер проекта (" + emptyNumbers[emptyNumbers.length - 1] + ")"}
                                    name={"number"}
                                    type="text"
                                    variant="standard"
                                    value={project.number}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <FormControl fullWidth variant={"standard"}>
                                    <InputLabel id="type_id">Тип проекта</InputLabel>
                                    <Select
                                        labelId="type_input_id"
                                        id="type_id"
                                        label="Тип проекта"
                                        name={'project_type_id'}
                                        onChange={e => handleChange(e)}
                                        value={project.type_id}
                                    >
                                        {listProjectType.map(type => {
                                            return <MenuItem key={type.id}
                                                             value={type.id}>{type.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={4}>
                                <FormControl fullWidth variant={"standard"}>
                                    <InputLabel id="status_id">Статус проекта</InputLabel>
                                    <Select
                                        labelId="status_id"
                                        id="status_id"
                                        name={'status_id'}
                                        label="Статус проекта"
                                        variant="standard"
                                        onChange={e => handleChange(e)}
                                        value={project.status_id}
                                    >
                                        {listProjectStatus ? listProjectStatus.map(status => {
                                            return <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>
                                        }) : []}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    fullWidth
                                    id="name"
                                    label="Наименование"
                                    name={'name'}
                                    type="text"
                                    variant="standard"
                                    value={project.name}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    fullWidth
                                    id="shortname"
                                    label="Внутреннее короткое название"
                                    name={'shortname'}
                                    type="text"
                                    variant="standard"
                                    value={project.shortname}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    fullWidth
                                    id="engname"
                                    label="Внутреннее короткое название на английском языке"
                                    name={'engname'}
                                    type="text"
                                    variant="standard"
                                    value={project.engname}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    name={'description'}
                                    rows={3}
                                    label="Описание"
                                    variant="standard"
                                    value={project.description}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        value={baseline_start}
                                        label={"Плановая дата старта"}
                                        inputFormat='DD.MM.YY'
                                        onChange={(newValue) => setBaseline_start(newValue)}
                                        renderInput={(params) => <TextField
                                            name="baseline_start" {...params} sx={{width: "100%"}}/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item md={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label={"Плановая дата завершения"}
                                        value={baseline_finish}
                                        inputFormat='DD.MM.YY'
                                        onChange={(newValue) => {
                                            setBaseline_finish(newValue)
                                        }}
                                        renderInput={(params) => <TextField
                                            name="baseline_finish" {...params} sx={{width: "100%"}}/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item md={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        value={start}
                                        label={"Фактическая дата старта"}
                                        inputFormat='DD.MM.YY'
                                        onChange={(newValue) => {
                                            setStart(newValue)
                                        }}
                                        renderInput={(params) => <TextField name="start" {...params}
                                                                            sx={{width: "100%"}}/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item md={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label={"Фактическая дата завершения"}
                                        value={finish}
                                        inputFormat='DD.MM.YY'
                                        onChange={(newValue) => {
                                            setFinish(newValue)
                                        }}
                                        renderInput={(params) => <TextField name="finish" {...params}
                                                                            sx={{width: "100%"}}/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item md={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label={"Дата запроса"}
                                        value={requirement_at}
                                        inputFormat='DD.MM.YY'
                                        onChange={(newValue) => {
                                            setRequirement_at(newValue)
                                        }}
                                        renderInput={(params) => <TextField
                                            name="requirement_at" {...params} sx={{width: "100%"}}/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item md={6}>
                                <FormControl fullWidth variant={"standard"}>
                                    <InputLabel id="label_release_id">Релиз</InputLabel>
                                    <Select
                                        labelId="label_release_id"
                                        id="release_id"
                                        label="Тип проекта"
                                        name='release_id'
                                        onChange={e => handleChange(e)}
                                        defaultValue={1}
                                    >
                                        {listReleases.map(r => {
                                            return <MenuItem key={r.id}
                                                             value={r.id}>{r.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={12} textAlign="right">
                                <Button onClick={e => {
                                    onSubmitForm(e)
                                }}>Сохранить изменения</Button>
                            </Grid>

                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    }
}

export {ProjectUpdate}


import React, {useEffect, useState} from 'react';
import AppBar                       from "@mui/material/AppBar";
import Toolbar                      from "@mui/material/Toolbar";
import Typography                   from "@mui/material/Typography";
import Box                          from "@mui/material/Box";
import {Button, LinearProgress}     from "@mui/material";
import {AddCircleOutline}           from "@mui/icons-material";
import Paper                        from "@mui/material/Paper";
import {userService}                from "../../services/userService";
import Table                        from "@mui/material/Table";
import TableHead                    from "@mui/material/TableHead";
import TableRow                     from "@mui/material/TableRow";
import TableCell                    from "@mui/material/TableCell";
import TableBody                    from "@mui/material/TableBody";


const UserList = () => {
    const [users, setUsers] = useState (null)
    const [loading, setLoading] = useState (false)

    useEffect (() => {
        userService.getAll ().then (_ => {
            console.log ('Получение пользователей', _)
            setUsers (_)
            setLoading (false)
        })
    }, [])

    if (loading) {
        return <LinearProgress/>
    }
    else {

        return (
            <Box
                component="main"
                sx={{flexGrow: 1, bgcolor: 'background.default', p: 0, mb: 2}}
            >
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>Список пользоваталей</Typography>
                    </Toolbar>
                </AppBar>
                <Paper elevation={3} sx={{m: 3, p: 3}}>
                    <Box justifyContent={'flex-end'} display={'flex'}>
                        <Button variant="outlined" href="/config/user/create" startIcon={<AddCircleOutline/>}>
                            Добавить нового пользователя
                        </Button>
                    </Box>
                    <Box component="div">
                        {users && <BasicTable users={users}/>}
                    </Box>
                </Paper>
            </Box>
        );
    }
};

export default UserList;


const BasicTable = ({users}) => {
    const number = '№'
    const username = 'Логин'
    const last_time = 'Дата последней регистрации'
    const created_at = 'Дата создания'
    const updated_at = 'Дата обновления'
    const is_active = 'Активный'
    const is_deleted = 'Помечен на удаление'
    const user_created_at = 'Дата создания'
    const user_updated_at = 'Дата обновления'

    return (
        <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
                <TableRow className="pa-8">
                    <TableCell className={"project_list table_cell pa-8"} align="right">{number}</TableCell>
                    <TableCell className="pa-8">{username}</TableCell>
                    <TableCell className="pa-8">{last_time}</TableCell>
                    <TableCell className="pa-8">{created_at}</TableCell>
                    <TableCell className="pa-8 w-210">{updated_at}</TableCell>
                    <TableCell className="pa-8 w-64">{is_active}</TableCell>
                    <TableCell className="pa-8">{is_deleted}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {users.map ((row, index, array) => (
                    <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell align='right' className={"project_list table_cell sm"}>{index + 1}</TableCell>
                        <TableCell className="pa-8"><Button href={'/config/user/' + row.id}>{row.username}</Button></TableCell>
                        <TableCell className="pa-8">{row.created_at}</TableCell>
                        <TableCell className="pa-8">{row.updated_at}</TableCell>
                        <TableCell className="pa-8">{row.last_time}</TableCell>
                        <TableCell className="pa-8">{row.is_active ? "Активный" : "Неактивный"}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
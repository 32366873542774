import React     from "react";
import {Grid}    from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow  from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table     from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {NavLink} from "react-router-dom";


const HelpValue = () => {

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h2>Описание сущности VALUE</h2>
            </Grid>
            <Grid item xs={8}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Параметр</TableCell>
                            <TableCell>Тип данных</TableCell>
                            <TableCell>Описание</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>id</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Технический идентификатор в базе данных.</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>parent_id</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Родительское value</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>project_id</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Проект, к которому относится данное value</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>title</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Краткое наименование</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>name</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}><td>Наименование</td></TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>eng_name</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Наименование на английском языке</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>priority_id</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Степень приоритизации</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>critical_id</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Степень критичности</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>description</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Краткое описание</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>deadline</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}><td>Дедлайн</td></TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>baseline</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Плановый срок</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>completed</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Процент готовности</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>created_at</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>дата создания</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>updated_at</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>дата обновления</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>is_active</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Активное для пользователя</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>is_deleted</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Помечено на удаление</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={4}>
                <p>Сущность <NavLink to={"/help/value"}>Value</NavLink> означает "ценность для Заказчика", "определенный объем предоставления услуг", для более простого понимания можно назвать <NavLink to={"/help/value"}>Value</NavLink> - целью наших активностей. Цель, в контексте данной программы означает промежуточная цель, которую выполнить для достижения общей глобальной цели проекта. Промежуточных целей в проекте может быть несколько, и они могут быть вложенныит друг в друга.</p>
                <p>С точки зрения всего проекта достижение каждой цели является шагом на пути к завершению проекта</p>
                <p>С точки зрения активности по достижению цели, то целью является как раз активность того или иного сотрудника для выполнения поставленой задачи. Целью этой задачи, как раз и является <NavLink to={"/help/value"}>Value</NavLink></p>
                <p>Проект имеет чёткую дату старта работ и сроки окончания работ по проекту.</p>
            </Grid>
            <Grid item xs={12}>
                <h2>Методолгия использования сущности ЦЕЛЬ</h2>
                <p>Под проектом понимается большой набор активностей направленных на одну цель. В ходе планирования проекта определяют несколько более мелких целей, которые должны быть достигнуты для выполнения поставленной цели - ПРОЕКТА.</p>
                <p>Все активности по проекту имеют свои плановые и фактические сроки и бюджет, который необходим для выполнения этих активностей.</p>
                <p>Проект имеет чёткую дату старта работ и сроки окончания работ по проекту.</p>
                <p>Есть цели проекта, которые составляют план проекта, а есть цели, которые не должныотображаться в плане.</p>
                {/*TODO: Продумать, архитектурное решение: по каким параметрам та или иная цель будет попадать в проектный план.*/}
                {/*TODO: Продумать, архитектурное решение: где используются цели (списки) и по каким параметрам будет происходить формирование данных списокв*/}
            </Grid>
            <Grid item xs={12}>
                <h2>Вопросы для размышления</h2>

                <ul className="list-ustyled">
                    <li>Определить алгоритм определения сроков для цели</li>
                    <li>Описать взаимосвязь целей между собой, как они влияют друг на друга, какие параметры одной цели влияют на параметры другой </li>
                    <li>Определить влияющие и зависимые параметры</li>
                </ul>

                <p>Под проектом понимается большой набор активностей направленных на одну цель. В ходе планирования проекта определяют несколько более мелких целей, которые должны быть достигнуты для выполнения поставленной цели - ПРОЕКТА.</p>
                <p>Все активности по проекту имеют свои плановые и фактические сроки и бюджет, который необходим для выполнения этих активностей.</p>
                <p>Проект имеет чёткую дату старта работ и сроки окончания работ по проекту.</p>
            </Grid>

        </Grid>
    </>
}

export default HelpValue

// noinspection ES6CheckImport

import {useAuth} from "../hook/useAuth";
import {Navigate} from "react-router-dom";
import * as React from "react";


export const RequireAuth = ({children}) => {
    const context = useAuth ();

    if (!context.user) {
        if (localStorage.getItem("access_token")) {
            context.user = {
                username    : localStorage.getItem ("username"),
                access_token: localStorage.getItem ("access_token"),
                user_id     : localStorage.getItem ("user_id")
            }
            return children
        }
        else return <Navigate to="/login" replace/>;
    }
    else return children
}

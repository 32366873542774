import * as React from "react";
import {useContext, useEffect} from "react";
import Paper from '@mui/material/Paper';
import {Grid} from "@mui/material";
import {getRuDateTime} from "../../utils";
import {useOutletContext} from "react-router";
import {LayoutContext} from "../../hok/LayoutProvider";


const FinanceTab = () => {
    const {metaData, changeMeta} = useContext(LayoutContext)
    const [project, activeTab, setActiveTab] = useOutletContext()

    useEffect(() => {
        changeMeta({
            mainTitle: "Бюджет проекта " + project?.number + ". " + project?.name,
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
        setActiveTab('finance')
    }, [])


    return <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2}} className="px-24">
        <Grid item xs={12}>
            <Paper elevation={3} sx={{p: 2}} component="div">
                <div className="project_single box flex small">
                    <span>{"дата создания: " + getRuDateTime(project.created_at)}</span>
                    <span>{"дата обновления: " + getRuDateTime(project.updated_at)}</span>
                </div>
                <h3>Finance TAB</h3>
            </Paper>
        </Grid>
    </Grid>
}

export {FinanceTab}

import React from "react";
import {Grid} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";

const HelpStart = () => {

    return<>
        <Grid container item xs={12}>
            <Grid item xs={12}><h2 className={"my-0"}>Описание методологии</h2></Grid>
            <Grid item xs={6}>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td>Параметр</td>
                            <td>Тип данных</td>
                            <td>Описание</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </Grid>
        </Grid>
    </>
}

export default HelpStart

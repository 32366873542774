import Box from "@mui/material/Box";
import * as React from "react";
import {useEffect, useState} from "react";
import {Outlet, useLocation, useParams} from "react-router-dom";
import {Button, ButtonGroup, LinearProgress} from "@mui/material";
import {projectsService} from "../../services/projectsService";
import AirplanemodeActiveOutlinedIcon from '@mui/icons-material/AirplanemodeActiveOutlined';
import CurrencyRubleOutlinedIcon from '@mui/icons-material/CurrencyRubleOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import Diversity3Icon from '@mui/icons-material/Diversity3';


const ProjectDetails = () => {

    const [project, setProject] = useState (null)
    const [activeTab, setActiveTab] = useState ('main')
    const [loading, setLoading] = useState (true)
    const [anchorEl, setAnchorEl] = useState (false)

    const {number} = useParams ()
    const location = useLocation()

    // useEffect(() => {
    //     setActiveTab('main')
    //     if (location.pathname.includes('plan')) {setActiveTab('plan')}
    //     if (location.pathname.includes('finance')) {setActiveTab('finance')}
    //     if (location.pathname.includes('actions')) {setActiveTab('actions')}
    //     if (location.pathname.includes('team')) {setActiveTab('team')}
    // },[location])

    useEffect (() => {
        projectsService.getByNumber (number).then (_ => {
            setProject (_)
            //setLoading (false)
        })
    }, [])

    const handleMenu = (event) => {
        setAnchorEl (event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl (null);
    };


    if (!project) {
        return <LinearProgress/>
    }
    else {
        return (<>
            <Box component="main" sx={{flexGrow: 1, bgcolor: 'background.default'}}>
                <Box
                    sx={{
                        display      : 'flex',
                        padding      : "24px 0 24px 24px",
                        flexDirection: 'column',
                        alignItems   : 'right',
                    }}
                    className="project_single_tabs_button"
                >
                    <ButtonGroup size="large" aria-label="large button group">
                        <Button key="main" disabled={activeTab==='main'} href={'/project/' + project.number} className={activeTab==='main' ? "active" : ""}><AirplanemodeActiveOutlinedIcon className="mr-8" />Общая информация</Button>
                        <Button key="plan" disabled={activeTab==='plan'} href={'/project/' + project.number + '/plan'} className={activeTab==='plan' ? "active" : ""}><ChecklistOutlinedIcon className="mr-8"/> План</Button>
                        <Button key="actions" disabled={activeTab==='actions'} href={'/project/' + project.number + '/actions'} className={activeTab==='actions' ? "active" : ""}><MiscellaneousServicesIcon className="mr-8"/>Активности</Button>
                        <Button key="finance" disabled={activeTab==='finance'} href={'/project/' + project.number + '/finance'} className={activeTab==='finance' ? "active" : ""}><CurrencyRubleOutlinedIcon className="mr-8"/>Бюджет</Button>
                        <Button key="team" disabled={activeTab==='team'} href={'/project/' + project.number + '/team'} className={activeTab==='team' ? "active" : ""}><Diversity3Icon className="mr-8"/>Команда</Button>
                    </ButtonGroup>
                </Box>
                <Outlet context={[project, activeTab, setActiveTab]}/>
            </Box>
        </>)
    }
}

export {ProjectDetails}

import {Link} from 'react-router-dom'


const NotfoundPage = () => {

    return (
        <div>
            Эта страница не найдена <Link to="/">вернуться на главную</Link>
        </div>
    )
}
export {NotfoundPage}
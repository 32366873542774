import {apiUrl} from "../utils/connectionStrings";
import {handleResponse} from "../helpers/handle-response";


export const typeService = {
    getAll, getByEntity, get_types_n_statuses, create, update, markToDelete
};


function getAll() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch(apiUrl + "/type", requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
    // return fetch(apiUrl + "/projects", requestOptions).then(res => {
    //     if (res.status >= 200 && res.status < 300) {
    //         return res;
    //     } else {
    //         let error = new Error(res.statusText);
    //         error.response = res;
    //         throw error
    //     }
    // })
    //     .then((res) => {
    //         if (res.headers['content-type'] !== 'application/json') {
    //             let error = new Error('Техническая ошибка на сервере');
    //             error.response = res;
    //             throw error
    //         }
    //         return res;
    //     })
    //     .then(res => res.json())
    //     .then(data => console.log('+', data))
    //     .catch((e) => {
    //         console.log('Error: ' + e.message);
    //         console.log(e.response);
    //     });
}

function getByProjectNumber(project_number) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch(apiUrl + "/type/get_by_project_number/" + project_number, requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}

function getByEntity(entity) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch(apiUrl + "/type/get_by_entity/" + entity, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function get_types_n_statuses(entity) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch(apiUrl + "/type/get_types_n_statuses/" + entity, requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}


function create(newType) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(newType),
    };
    console.log(requestOptions.body)
    return fetch(apiUrl + "/type/create/new", requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}

function update(updateStatusModel) {
    console.log("typeService.js : 107", updateStatusModel)
    const requestOptions = {
        method : "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body   : JSON.stringify (updateStatusModel),
    };
    return fetch (apiUrl + "/type/update/" + updateStatusModel.id, requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}
function markToDelete(entity, id) {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch(apiUrl + "/type/mark_to_delete/" + entity + "/"  + id, requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}

import React           from 'react';
import {BrowserRouter} from 'react-router-dom'
import './index.css';
import App             from './App';
import {ThemeProvider} from "@mui/material";
import {theme}         from "./theme/theme";
import {createRoot}    from "react-dom/client";


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>
);

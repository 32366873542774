import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Paper from '@mui/material/Paper';
import {Grid, Table, TableBody, TableCell, TableRow} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import {useOutletContext} from "react-router";
import {LayoutContext} from "../../hok/LayoutProvider";
import {valueService} from "../../services/valueService";
import moment from "moment";


const PlanTab = () => {
    const {metaData, changeMeta} = useContext(LayoutContext)
    const [project, activeTab, setActiveTab] = useOutletContext()
    const [valuesList, setValuesList] = useState([])

    useEffect(() => {
        changeMeta({
            mainTitle: "План проекта " + project?.number + ". " + project?.name,
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
        setActiveTab('plan')
    }, [project])
    
    useEffect(() => {
        valueService.get_values_by_project_id(project.id).then(_ => {
            setValuesList(_)
        })
    },[project])


    if(valuesList.length !== 0){
        return <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2}} className="px-24">
            <Grid item xs={12}>
                <Paper elevation={3} sx={{p: 2}} component="div">
                    <div className="project_single box flex small">
                        <span>{"дата создания: " + project.created_at_str}</span>
                        <span>{"дата обновления: " + project.updated_at_str}</span>
                    </div>
                    <h5>Общая информация о проекте</h5>
                    {valuesList.length>0 && <ValuesTable valuesList={valuesList}/>}
                </Paper>
            </Grid>
        </Grid>
    }
    else {
        return ""
    }

}

const ValuesTable = ({valuesList}) => {


    return (<>
        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell>№</TableCell>
                    <TableCell>Наименование</TableCell>
                    <TableCell>Начало</TableCell>
                    <TableCell>Завершение</TableCell>
                    <TableCell>Требуется, ч.</TableCell>
                    <TableCell>Срок готовности</TableCell>
                    <TableCell>Ответственный</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {valuesList.map((value,index) => <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{value.name}</TableCell>
                    <TableCell>{value.baseline_start ? moment(value.baseline_start).format("DD.MM.YYYY") : ""}</TableCell>
                    <TableCell>{value.baseline_finish ? moment(value.baseline_finish).format("DD.MM.YYYY") : ""}</TableCell>
                    <TableCell>{value.remaining}</TableCell>
                    <TableCell>{value.deadline ? moment(value.deadline).format("DD.MM.YYYY") : ""}</TableCell>

                </TableRow>)}
            </TableBody>
        </Table>
    </>)

}

export {PlanTab}

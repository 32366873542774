import {apiUrl}         from "../utils/connectionStrings";
import {handleResponse} from "../helpers/handle-response";


export const userService = {
    getAll, create, getById, login, getMe
};


function getAll () {
    const requestOptions = {
        method : "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch (apiUrl + "/users", requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}

function create (newUser) {
    const requestOptions = {
        method : "POST",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
        },
        body   : JSON.stringify (newUser)
    };
    return fetch (apiUrl + "/user/create", requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}

function getById (id) {
    const requestOptions = {
        method : "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
        },
    };
    return fetch (apiUrl + "/user/" + id, requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}

    function login (credModel) {
        const str = Object.entries(credModel).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&')
        console.log('str', str)
        const requestOptions = {
            method : "POST",
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            body   : str
        };
        return fetch (apiUrl + "/user/login", requestOptions)
            .then (handleResponse)
            .then ((_) => {
                console.log('Возврат юзера', _)
                return _;
            })
            .catch(error => {
                return error
            });
    }

    function getMe (token) {
        const requestOptions = {
            method : "GET",
            headers: {
                "Content-Type": 'application/json; charset=UTF-8',
                "Authorization": "Bearer " + token
            },
        };
        return fetch (apiUrl + "/user/me", requestOptions)
            .then (handleResponse)
            .then ( _ => {
                return _
            }).catch( error => {return error} );
    }
import Box from "@mui/material/Box";
import {useLocation, useNavigate} from "react-router-dom";
import * as React from "react";
import {useRef} from "react";
import {Button, TextField} from "@mui/material";
import {useAuth} from "../hook/useAuth";
import {formToBodySerialize} from "../utils";


const LoginPage = () => {

    const navigate = useNavigate ()
    const location = useLocation ()
    const {signIn} = useAuth ()
    const fromPage = location.state?.from?.pathname || '/'
    const loginForm = useRef()

    const login = e => {
        e.preventDefault ()
        const formObject = formToBodySerialize(new FormData(loginForm.current))
        signIn (formObject, () => navigate ("/", {replace: true}))
    }

    return (
        <>
            <Box
                component="main"
                sx={{flexGrow: 4, bgcolor: "background.default", p: 5}}
            >
                <Box
                    component="form"
                    onSubmit={login}
                    sx={{
                        "& > :not(style)": {m: 0, width: '100%'}
                    }}
                    noValidate
                    autoComplete="off"
                    ref={loginForm}
                >
                    <TextField id="login" label="Логин" variant="standard" name='username'/>
                    <TextField id="pass" label="Пароль" variant="standard" name='password'/>
                    <div className="flex content-between">
                        <Button variant="outlined" color='primary' className="mt-24"
                                onClick={login}>{"Войти"}</Button>
                    </div>
                </Box>
            </Box>
        </>
    );
};

export default LoginPage;

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {projectsService} from "../services/projectsService";
import {useCallback, useEffect, useState} from "react";
import Snackbar from '@mui/material/Snackbar'
import {Alert} from "@mui/material";

const PnlPage = () => {
    const [projects, setProjects] = useState([])
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true)

    const loadProjects = useCallback(async () => {
        try {
            await projectsService.getAll().then(_ => {
                setProjects(_)
                setLoading(false)
                setOpen(true)
            })
        } catch (err) {
            console.log('Ошибка загрузки данных', err.message)
        }
    }, [])

    useEffect(() => {
        loadProjects()
    }, [])

    return (<>

            <AppBar
                position="fixed"
                sx={{width: `calc(100% - ${240}px)`, ml: `${240}px`, mb:`${260}px`}}
            >
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">Управление финансами</Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="main"
                sx={{flexGrow: 1, bgcolor: 'background.default', p: 3}}
            >
                {!loading && <Box element='div' sx={{pt: 8}}>
                    {projects.map(project => {
                        return <div><h3>{project.name}</h3></div>
                    })}
                </Box>}
                <Snackbar open={open} autoHideDuration={700} onClose={()=>{console.log('Привет')}}>
                    <Alert onClose={()=>{console.log("dsfs")}} severity="success" sx={{ width: '100%' }}>
                        This is a success message!
                    </Alert>
                </Snackbar>
            </Box>
        </>
    )
}

export {PnlPage}
import React from 'react';
import {Grid} from "@mui/material";
import Paper from "@mui/material/Paper";

const ValueDetails = () => {
    return (<>
            <Grid container>
                <Grid item md="12">
                    <Paper elevation={3} sx={{m: 3, p: 3}}>
                        <h1>Привет</h1>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default ValueDetails;
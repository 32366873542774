import {Grid} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {NavLink} from "react-router-dom";
import React from "react";


const HelpTeam = () => {

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h2>Описание сущности TEAM</h2>
            </Grid>
            <Grid item xs={8}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Параметр</TableCell>
                            <TableCell>Тип данных</TableCell>
                            <TableCell>Описание</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>id</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}><td>Идентификатор в базе данных</td></TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>name</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>string</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}><td>Наименование команды</td></TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>description</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>string</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Описание</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>start_date</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>datetime</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Дата и время старта назначенности</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>finish_date</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>datetime</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Дата и время окончания назначенности</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>person_id</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>id русурса</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>project_id</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>id проекта, на который создаётся назначенность</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>value_id</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>id цели, на котороую создаётся назначенность</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>id_task</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>id задачи, на которую создаётся назначенность</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>id_type</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>тип назначенности</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>id_status</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Статус назначенности</TableCell>
                        </TableRow>

                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>created_at</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>дата создания</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>updated_at</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>дата обновления</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>is_active</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>bool</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>активная ли запись</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>is_deleted</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>bool</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Помечено на удаление</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={4}>
                <p>Сущность Team означает "команда" (назначенность). Команда, в контексте данной программы означает распределение ресурсов на проекты, цели, активности.</p>
            </Grid>
            <Grid item xs={12}>
                <h2>Методолгия использования сущности TEAM</h2>
                <p>Для качественного распределения ресурсов и наглядного понимания доступности ресурсов (хватает нам команды или не хватает) необходимо периодически определять ресурсы по командам и проводить назначенность на проекты. </p>
                <p>Назначенность на проекты позволяет выдеть полную картину покрытия всех задач ресурсами.</p>
            </Grid>

        </Grid>
    </>
}

export default HelpTeam
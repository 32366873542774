import {apiUrl} from "../utils/connectionStrings";
import {handleResponse} from "../helpers/handle-response";


export const releaseService = {
    getAll, get_by_id, createRelease
};


function getAll () {
    const requestOptions = {
        method : "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch (apiUrl + "/releases", requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}

function get_by_id (id) {
    const requestOptions = {
        method : "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch (apiUrl + "/release/" + id, requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}

function createRelease (body) {
    const requestOptions = {
        method : "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: body
    };
    return fetch (apiUrl + "/release/create", requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}




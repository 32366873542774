import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Layout} from "../components/layout/Layout";
import {ProjectsList} from "../components/project/ProjectsList";
import {ProjectCreate} from "../components/project/ProjectCreate";
import {ValuesList} from "../components/value/ValuesList";
import ValueCreate from "../components/value/ValueCreate";
import {PnlPage} from "../pages/PnlPage";
import {StatusList} from "../components/status/StatusList";
import {TypeList} from "../components/type/TypeList";
import UserList from "../components/user/UserList";
import UserCreate from "../components/user/UserCreate";
import UserCard from "../components/user/UserCard";
import {StaffList} from "../components/staff/StaffList";
import {ActivitiesPage} from "../pages/ActivitiesPage";
import HelpPage from "../components/help";
import HelpStart from "../components/help/start/HelpStart";
import HelpProject from "../components/help/project/HelpProject";
import HelpValue from "../components/help/value/HelpValue";
import HelpTeam from "../components/help/team/HelpTeam";
import HelpInvoice from "../components/help/invoice/HelpInvoice";
import {NotfoundPage} from "../pages/NotfoundPage";
import PersonSingle from "../components/person/PersonSingle";
import PersonCreate from "../components/person/PersonCreate";
import Assignment from "../components/assignment/Assignment";
import {LayoutProvider} from "../hok/LayoutProvider";
import {ProjectUpdate} from "../components/project/ProjectUpdate";
import MainTab from "../components/project/MainTab";
import {ProjectDetails} from "../components/project/ProjectDetails";
import {PlanTab} from "../components/project/PlanTab";
import {FinanceTab} from "../components/project/FinanceTab";
import {ActionsTab} from "../components/project/ActionsTab";
import {TeamTab} from "../components/project/TeamTab";
import ReleasesList from "../components/release/ReleasesList";
import ReleaseDetails from "../components/release/ReleaseDetails";
import ValueDetails from "../components/value/ValueDetails";
import ValuesTreeView from "../components/value/ValuesTreeView";

const Main = () => <LayoutProvider>
    <Routes>
        <Route path='/' element={<Layout/>}>
            <Route index element={<ProjectsList/>}/>
            <Route path='project/:number' element={<ProjectDetails/>}>
                <Route index element={<MainTab/>} />
                <Route path='plan' element={<PlanTab/>}/>
                <Route path='finance' element={<FinanceTab/>}/>
                <Route path='actions' element={<ActionsTab/>}/>
                <Route path='team' element={<TeamTab/>}/>
            </Route>
            <Route path='project/create' element={<ProjectCreate/>}/>
            <Route path='project/:projectNumber/update' element={<ProjectUpdate/>}/>

            <Route path='values' element={<ValuesList/>}/>
            <Route path='values_tree' element={<ValuesTreeView/>}/>
            <Route path='value/create' element={<ValueCreate/>}/>
            <Route path='value/:id' element={<ValueDetails/>}/>
            <Route path='releases' element={<ReleasesList/>}/>
            <Route path='release/:name' element={<ReleaseDetails/>}/>

            <Route path='pnl' element={<PnlPage/>}/>
            <Route path='config/dictionaries/status' element={<StatusList/>}/>
            <Route path='config/dictionaries/type' element={<TypeList/>}/>
            <Route path='config/users' element={<UserList/>}/>
            <Route path='config/user/create' element={<UserCreate/>}/>
            <Route path='config/user/update' element={<TypeList/>}/>
            <Route path='config/user/:id' element={<UserCard/>}/>
            <Route path='staff' element={<StaffList/>}/>
            <Route path='assignment' element={<Assignment/>}/>
            <Route path='person/:id' element={<PersonSingle/>}/>
            <Route path='person/create' element={<PersonCreate/>}/>
            <Route path='activities' element={<ActivitiesPage/>}/>
            <Route path='help' element={<HelpPage/>}>
                <Route index element={<HelpStart/>}/>
                <Route path='start' element={<HelpProject/>}/>
                <Route path='project' element={<HelpProject/>}/>
                <Route path='value' element={<HelpValue/>}/>
                <Route path='task' element={<HelpPage/>}/>
                <Route path='estimate' element={<HelpPage/>}/>
                <Route path='finance' element={<HelpPage/>}/>
                <Route path='person' element={<HelpPage/>}/>
                <Route path='team' element={<HelpTeam/>}/>
                <Route path='invoice' element={<HelpInvoice/>}/>
            </Route>
            <Route path='*' element={<NotfoundPage/>}/>
        </Route>
    </Routes>
</LayoutProvider>

export default Main
import {apiUrl}         from "../utils/connectionStrings";
import {handleResponse} from "../helpers/handle-response";


export const staffService = {
    getAll, get_by_id, createPerson
};


function getAll () {
    const requestOptions = {
        method : "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch (apiUrl + "/persons", requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}

function get_by_id (id) {
    const requestOptions = {
        method : "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch (apiUrl + "/person/" + id, requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}

function createPerson (body) {
    const requestOptions = {
        method : "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: body
    };
    return fetch (apiUrl + "/person/create", requestOptions)
        .then (handleResponse)
        .then ((_) => {
            return _;
        });
}




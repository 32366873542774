import React, {useMemo, useState} from 'react';
import {valueService} from "../../services/valueService";
import {LinearProgress} from "@mui/material";

const ValuesTreeView = () => {

    const [valuesTree, setValuesTree] = useState(null)
    
    useMemo(()=> {
        valueService.getValuesTreeSorted().then(_ => {
            console.log("111", _)
            setValuesTree(_)
        })
    },[])

    if (!valuesTree) return <LinearProgress />
    else return valuesTree.map( (v, i) => <Value key={i} {...v}/>)
}
export default ValuesTreeView;

const Value = (props) => {

    const { id, name, description, children, parent_id } = props;
    console.log(id, children, name, parent_id)
    
    return (
        <div>
            <h4>{name}</h4>
            <p>{description}</p>
            {children?.length>0 && children?.map(v => <Value key={v.id} {...v} />)}
        </div>
    );

}

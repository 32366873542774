import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {Button, Grid, LinearProgress, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Paper from "@mui/material/Paper";
import {typeService} from "../../services/typeService";
import {formToBodySerialize, getRuDateTime} from "../../utils";
import {useNavigate} from "react-router-dom";
import lodash from "lodash";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {LayoutContext} from "../../hok/LayoutProvider";
import UndoIcon from "@mui/icons-material/Undo";


export const TypeList = () => {
    const [loading, setLoading] = useState (false)
    const [typeList, setTypeList] = useState (null)
    const [anchorEl, setAnchorEl] = useState (false)
    const [entity, setEntity] = React.useState ('project');
    const [newType, setNewType] = React.useState ({name: "", description: "", position: null, entity: entity});
    const [updateType, setUpdateType] = React.useState ({name: "", description: "", position: null});
    const [isUpdate, setIsUpdate] = React.useState (false);
    const {metaData, changeMeta} = useContext(LayoutContext)

    const navigate = useNavigate ()
    const updateTypeForm = useRef ()
    const createTypeForm = useRef ()

    const handleMenu = (event) => {
        setAnchorEl (event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl (null);
    };


    useEffect (() => {
        typeService.getByEntity (entity).then (_ => {
            console.log("TypeList.jsx : 43", _)
            setTypeList (_)
            setLoading (false)
        })
    }, [entity, isUpdate, newType])

    useEffect(() => {
        changeMeta({
            mainTitle: "Типы",
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
    }, [])


    const createNewType = e => {
        e.preventDefault ()
        if (isUpdate) {
            let typeModel = lodash.cloneDeep (newType)
            const formObject = formToBodySerialize (new FormData (updateType.current))
            typeModel.name = formObject.name
            typeModel.description = formObject.description
            typeModel.isActive = true
            typeService.create (typeModel).then (_ => {
                setIsUpdate (false)
                navigate ('/config/dictionaries/type')
            })
        }
        else {
            const formObject = formToBodySerialize (new FormData (createTypeForm.current))
            newType.name = formObject.name
            newType.description = formObject.description
            newType.entity = entity
            newType.isActive = true
            typeService.create (newType).then (_ => {
                setNewType ({name: "", description: "", position: null, entity: entity})
                navigate ('/config/dictionaries/type')
            })
        }
    }

    const updateNewType = e => {
        e.preventDefault ()
        typeService.update (updateType).then (_ => {
            setIsUpdate (false)
            navigate ('/config/dictionaries/type')
        })
    }

    const changeUpdateTypeProps = e => {
        updateType[e.target.name] = e.target.value
        setUpdateType (updateType)
    }

    const handleChange = (event, newEntity) => {
        setEntity (newEntity);
        setIsUpdate (false);
        setNewType ({name: "", description: "", position: null, entity: newEntity, isActive: true})
    };
    const control = {
        value    : entity,
        onChange : handleChange,
        exclusive: true,
    };

    const buttons = [
        <ToggleButton value="project" key="project">Проект</ToggleButton>,
        <ToggleButton value="value" key="value">Цель</ToggleButton>,
        <ToggleButton value="estimate" key="estimate">Оценка</ToggleButton>,
        <ToggleButton value="release" key="release">Релиз</ToggleButton>,
    ];

    const typeEdit = (e, i) => {
        e.preventDefault ()
        setUpdateType (typeList[i])
        setIsUpdate (true)
    }

    const markToDelete = i => {
        const t = typeList[i]
        typeService.markToDelete (t.entity, t.id).then (_ => {
            setTypeList (_)
        })
    }

    const unmarkFromDelete = i => {
        const t = typeList[i]
        t.is_deleted = false
        typeService.update(t).then(_ => {
            let newTypeList = lodash.cloneDeep(typeList)
            console.log("TypeList.jsx : 134", _)
            newTypeList[i] = _
            setTypeList(newTypeList)
        })
    }



    const Types = ({typeList, typeEdit}) => {
        return typeList.map ((t, i) => {
            return (<TableRow key={i} sx={{'&:last-child td, &:last-child th': {border: 0}}}
                              onContextMenu={e => {typeEdit (e, i)}} className="py-4">
                <TableCell component="th" scope="row">{t.name}</TableCell>
                <TableCell>{t.description}</TableCell>
                <TableCell align={"right"}>
                    {!t.is_deleted && <DeleteOutlineIcon onClick={e => markToDelete(i)} className="tech-text btn_delete"/>}
                    {t.is_deleted && <UndoIcon onClick={e => unmarkFromDelete(i)} className="tech-text btn_delete"/>}
                </TableCell>
            </TableRow>)
        })
    }

    const TypeCreateForm = ({createNewType}) => {

        return (<Box
            component="form"
            noValidate
            autoComplete="off"
            className="pa-24"
            onSubmit={createNewType}
            ref={createTypeForm}
        >
            <div>
                <TextField
                    id="outlined-required"
                    label="Новый тип"
                    name="name"
                    variant="standard"
                    className="w100"
                    autoFocus
                />
                <TextField
                    id="outlined-required"
                    label="Описание типа"
                    name="description"
                    variant="standard"
                    className="w100 mt-24"
                    multiline
                    rows={4}
                />
                <Button variant="outlined" color='success' className="mt-24"
                        type="submit">{"Создать новый тип"}</Button>
            </div>
        </Box>)
    }

    const TypeUpdateForm = ({updateNewType, updateType, createNewType, changeUpdateTypeProps}) => {

        return (<Box
            component="form"
            noValidate
            autoComplete="off"
            className="pa-24"
            onSubmit={updateNewType}
            ref={updateTypeForm}
        >
            <div className="flex content-between small-text tech-text mb-24">
                <span>{"Создан: " + getRuDateTime (updateType.created_at)}</span><span>{"Последнее обновление: " + getRuDateTime (updateType.updated_at)}</span>
            </div>
            <div>
                <TextField
                    id="outlined-required"
                    name="name"
                    label="Новый статус"
                    variant="standard"
                    className="w100"
                    onChange={changeUpdateTypeProps}
                    defaultValue={updateType.name}
                />
                <TextField
                    id="outlined-required"
                    name="description"
                    defaultValue={updateType.description}
                    onChange={changeUpdateTypeProps}
                    label="Описание"
                    variant="standard"
                    className="w100 mt-24"
                    multiline
                    rows={4}
                />
                <div className="flex content-between">
                    <Button variant="outlined" color='success' className="mt-24"
                            type="submit">{"Обновить статус"}</Button>
                    <Button variant="outlined" color='primary' className="mt-24"
                            onClick={createNewType}>{"Создать новый статус"}</Button>
                </div>
            </div>
        </Box>)
    }

    if (loading) return <LinearProgress/>
    else return (<>
                <Grid container rowSpacing={1} p={2} columnSpacing={{xs: 1, sm: 2}}>
                    <Grid item xs={6}>
                        <Paper>
                            <ToggleButtonGroup {...control} aria-label="Medium sizes">
                                {buttons}
                            </ToggleButtonGroup>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} p={2} columnSpacing={{xs: 1, sm: 2}}>
                    <Grid item xs={4}>
                        <Paper>
                            {isUpdate ? newType && <TypeUpdateForm
                                updateNewType={updateNewType}
                                updateType={updateType}
                                changeUpdateTypeProps={changeUpdateTypeProps}
                                createNewType={createNewType}
                            /> : <TypeCreateForm
                                 createNewType={createNewType}
                                 newType={newType}
                             />}
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper elevation={3} sx={{p: 2}}>
                            {typeList && <Table size="small" aria-label="a dense table" className="pa-8 ma-0">
                                <TableBody>
                                    <Types typeEdit={typeEdit} typeList={typeList}/>
                                </TableBody>
                            </Table>}
                        </Paper>
                    </Grid>
                </Grid>
        </>
    )
}
import * as React from 'react';
import {createContext, useState} from 'react';


export const LayoutContext = createContext(null)


export const LayoutProvider = ({children}) => {

    const [metaData, setMetaData] = useState({
        mainTitle:"",
    })
    
    const changeMeta = newMetaData => setMetaData(newMetaData)

    return <LayoutContext.Provider value={{metaData, changeMeta}}>
        {children}
    </LayoutContext.Provider>;
};
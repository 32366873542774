export const getRuDateTime = date => {
    const d = new Date(date).toLocaleDateString();
    const t = new Date(date).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
    return d + " " + t
}

export const getRuDate = date => {
    return date ? new Date(date).toLocaleDateString() : "Не задано"
}

export const getDateObject = date => {
    console.log("DATE", date)
    const str = date
    const day = str.slice(0, 1)
    const month = str.slice(4, 5)
    const year = '20' + str.slice(7, 8)
    return ""
}


export const formToBodySerialize = form => {
    let obj = {};
    for (const key of form.keys()) {
        obj[key] = form.get(key);
    }
    return obj
}


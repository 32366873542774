import React, {useEffect, useState} from 'react';
import {LinearProgress}             from "@mui/material";
import Box                          from "@mui/material/Box";
import AppBar                       from "@mui/material/AppBar";
import Toolbar                      from "@mui/material/Toolbar";
import Typography                   from "@mui/material/Typography";
import {userService}                from "../../services/userService";


const UserCard = () => {
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(null)

    useEffect (() => {
        setLoading (true)
        userService.getById(1).then (_ => {
            console.log ('Получение пользователя', _)
            setUser (_)
            setLoading (false)
        })
    }, [])


    if (loading) {
        return <LinearProgress/>
    }
    else {
        // TODO: Нет карточки пользователя, необходимо продумать UX/UI и сделать
        return (
            <Box
                component="main"
                sx={{flexGrow: 1, bgcolor: 'background.default', p: 0, mb: 2}}
            >
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>Системный пользователь </Typography>
                    </Toolbar>
                </AppBar>
                <h3>{user?.username}</h3>
                <h3>{user?.access_token}</h3>
                <h3>{user?.created_at}</h3>
                <h3>{user?.updated_at}</h3>

            </Box>
        );
    }
};

export default UserCard;
import React from "react";
import {Grid} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import {NavLink} from "react-router-dom";

const HelpInvoice = () => {

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h2>Описание сущности INVOICE</h2>
            </Grid>
            <Grid item xs={8}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Параметр</TableCell>
                            <TableCell>Тип данных</TableCell>
                            <TableCell>Описание</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>id</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}><td>Идентификатор в базе данных</td></TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>number</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}><td>Сквозной номер проекта, для идентификации и взаимодейсттвия внутри компании и с Заказчиком.</td></TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>name</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Полное наименование проекта</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>shortname</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Краткое наименование проекта</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>engname</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Английское обозначение проекта</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>description</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Краткое описание проекта</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>team_project_link</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Ссылка на проект в трекере</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>project_folder</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Папка проекта</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>project_type</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Тип проекта</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>project_state</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Статус проекта</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>completed</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Процент готовности проекта</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>baseline_start</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Планируемая дата старта работ</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>baseliine_finish</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Планируемая дата завершения работ</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>start</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>дата старта работ</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>finish</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>дата окончания работ</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: "1px"}}}>
                            <TableCell sx={{padding: "4px 8px"}}>created_at</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>дата создания</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>updated_at</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>дата обновления</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>requirement_at</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>integer</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>дата формирования запроса</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>is_active</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>bool</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>активная ли запись</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell sx={{padding: "4px 8px"}}>is_deleted</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>bool</TableCell>
                            <TableCell sx={{padding: "4px 8px"}}>Помечено на удаление</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={4}>
                <p>Сущность Invoice означает "счет". Счет фиксирует факт оплаты за проект либо от Заказчика в сторону генерального подрядчика, либо от генподрядчика в сторону СПК</p>
                <p>Счет является документом, подтверждающим факт оплаты</p>
            </Grid>
            <Grid item xs={12}>
                <h2>Методолгия использования сущности Проект</h2>
            </Grid>

        </Grid>
    </>
}

export default HelpInvoice

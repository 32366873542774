import {apiUrl}         from "../utils/connectionStrings";
import {handleResponse} from "../helpers/handle-response";


export const wcalendarService = {
    getWorkingDays
};



function getWorkingDays (period) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    return fetch(apiUrl + "/calendar/count_working_days_from_period/?" + period, requestOptions)
        .then(handleResponse)
        .then((_) => {
            return _;
        });
}
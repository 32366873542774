
export const getNormalDate = localDate => {
    if (localDate) {
        console.log('localDate functions.js:4', localDate)
        let d = Number (localDate.split (".")[0])
        let m = Number (localDate.split (".")[1]) // номер месяца указывается из массива с 0 по 11
        let y = Number ("20" + (localDate.split (".")[2])) // обязательно 4х символьный формат года
        return y + "-" + m + "-" + d
    }
    else return null
}
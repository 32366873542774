import * as React from 'react';
import {createContext, useState} from 'react';
import {userService} from "../services/userService";


export const AuthContext = createContext(null)


export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);

    const kickUser = () => {
        localStorage.removeItem("access_token")
        localStorage.removeItem("user_id")
        localStorage.removeItem("username")
    }
    
    const signIn = (loginUser, callback) => {
        userService.login(loginUser).then(_ => {
            if (_?.id > 0) {
                localStorage.setItem("access_token", _?.access_token)
                localStorage.setItem("user_id", _?.id)
                localStorage.setItem("username", _?.username)
                setUser(_)
                callback();
            }
            else console.log('Привет ку-ку')
        })
    };

    let signOut = callback => {
        setUser(null);
        kickUser()
        callback();
    };

    const value = {user, setUser, signIn, signOut};

    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>;
};
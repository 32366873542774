import React, {useContext, useEffect, useRef, useState} from 'react';
import {Autocomplete, TextField} from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {Layer, Rect, Stage, Text} from "react-konva"
import useWindowSize from "../../hook/useWindowSize";
import {queryStringParams} from "../../utils/queryStringParams";
import {wcalendarService} from "../../services/wcalendarService";
import {LayoutContext} from "../../hok/LayoutProvider";


const Assignment = () => {
    const window = useWindowSize ();

    // Сотрудники
    const persons = [
        {name: "Александр", familyname: "Хорьков", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Андрей", familyname: "Синичкин", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Артур", familyname: "Шагивалеев", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Сергей", familyname: "Фёдоров", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Ильдар", familyname: "Валеев", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Ильнур", familyname: "Талипов", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Роман", familyname: "Румянцев", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Ильнур", familyname: "Идиатуллов", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Сергей", familyname: "Туаев", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Искандер", familyname: "Гайнуллин", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Юрий", familyname: "Алексеев", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Николай", familyname: "Егоров", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Никита", familyname: "Липатов", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Николай", familyname: "Хевронин", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Андрей", familyname: "Музыченко", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Ришат", familyname: "Муратов", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Ильдар", familyname: "Гумеров", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Ильдар", familyname: "Шайдулин", discipline: "DEV", grade: "DEV4", dayof: []},
        {name: "Николай", familyname: "Дзенчарский", discipline: "DEV", grade: "DEV4", dayof: []}
    ]
    const canvasArea = useRef (null)

    const dimensions = {
        scale       : 1.0,
        browserArea : {
            width : window[0],
            height: window[1],
        },
        pageWidth   : document.documentElement.scrollWidth,
        pageHeight  : document.documentElement.scrollHeight,
        navWidth    : 240,
        topNavHeight: 64,
    }
    const mainArea = {
        width : dimensions.browserArea.width - dimensions.navWidth - 64 - 32 - 2,
        height: dimensions.browserArea.height - dimensions.topNavHeight - 64 - 32 - 32 - 56 - 2,
    }
    
    const [period, setPeriod] = useState({start_period: "2023-01-01", finish_period: "2023-05-01", }) 
    const [days, setDays] = useState(null)

    const {metaData, changeMeta} = useContext(LayoutContext)

    useEffect(() => {
        changeMeta({
            mainTitle: "Назначенность",
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
    }, [])


    useEffect(()=> {
        wcalendarService.getWorkingDays (queryStringParams (period)).then (_ => {
            setDays(_)
        })

    }, [])

    if (!days) return null
    else {
        const areaDayWidth = mainArea.width / days.working_days
        return (<>
                <Paper className="ma-32 pa-16" elevation={3}>
                    <Autocomplete
                        id="personal_assignment"
                        freeSolo
                        options={persons.map ((option) => {return option.familyname + " " + option.name })}
                        renderInput={(params) => <TextField {...params} label="Сотрудник"/>}
                        className={"mb-16 pa-0"}
                    />
                    <Box component="div" ref={canvasArea} sx={{border: "1px solid #AAA"}}>
                        <Stage width={mainArea.width} height={mainArea.height}>
                            <Layer>
                                <Text text={"Ширина области: " + mainArea.width + " px. Высота окна:" + window[1]} fontSize={15} x={100} y={100}/>
                                {areaDays(days, areaDayWidth, mainArea).map( (x,i) => {
                                    return <>
                                        <Text text={i+1} fontSize={10} x={x+3} y={200}/>
                                        <Rect key={i} x={x} y={100} width={areaDayWidth} height={mainArea.height} stroke="#777" strokeWidth={0.2}/>
                                    </>
                                })}
                            </Layer>
                        </Stage>
                    </Box>
                </Paper>
            </>)
    }
};


export default Assignment;

const areaDays = (days, areaDayWidth, mainArea) => {
    let array = []
    for (let i = 0; i <= days.working_days; i++) {
        array.push (i*areaDayWidth)
    }
    return array
}
import * as React from "react";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {Autocomplete, Button, Grid, LinearProgress, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Paper from "@mui/material/Paper";
import {statusService} from "../../services/statusService";
import {formToBodySerialize} from "../../utils";
import {useNavigate} from "react-router-dom";
import lodash from "lodash";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {typeService} from "../../services/typeService";
import {LayoutContext} from "../../hok/LayoutProvider";
import UndoIcon from '@mui/icons-material/Undo';


export const StatusList = () => {

    const [entity, setEntity] = React.useState('project');
    const NEW_STATUS_TEMPLATE = {
        name: "",
        type_id: null,
        description: "",
        position: null,
        entity: entity
    }


    const [inputValue, setInputValue] = useState("")
    const [selectedType, setSelectedType] = useState(null)
    const [loading, setLoading] = useState(false)
    const [statusList, setStatusList] = useState(null)
    const [typeList, setTypeList] = useState(null)
    const [anchorEl, setAnchorEl] = useState(false)
    const [newStatus, setNewStatus] = React.useState(NEW_STATUS_TEMPLATE);
    const [updateStatus, setUpdateStatus] = React.useState(NEW_STATUS_TEMPLATE);
    const [isUpdate, setIsUpdate] = React.useState(false);

    const navigate = useNavigate()
    const updateStatusForm = useRef()
    const createStatusForm = useRef()

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const {metaData, changeMeta} = useContext(LayoutContext)

    useEffect(() => {
        changeMeta({
            mainTitle: "Статусы",
            breadcrumbs: [
                {title: "Главная", url: "/"}
            ]
        })
    }, [])


    useEffect(() => {
        typeService.getByEntity(entity).then(_ => {
            console.log('Загрузка списка типов с сервера', _)
            setTypeList(_)
            setLoading(false)
        })
    }, [entity])

    const selectTypeFunction = (e, selectedType) => {
        setStatusList(selectedType?.statuses_list)
        setInputValue(selectedType?.name)
        setSelectedType(selectedType)
    }


    const createNewStatus = e => {
        e.preventDefault()
        if (isUpdate) {
            let statusModel = lodash.cloneDeep(newStatus)
            const formObject = formToBodySerialize(new FormData(updateStatusForm.current))
            statusModel.type_id = formObject.type_id
            statusModel.name = formObject.name
            statusModel.description = formObject.description
            statusModel.isActive = true
            statusService.create(statusModel).then(_ => {
                navigate('/config/dictionaries/status')
            })
        } else {
            const formObject = formToBodySerialize(new FormData(createStatusForm.current))
            newStatus.name = formObject.name
            newStatus.description = formObject.description
            newStatus.type_id = selectedType.id
            newStatus.isActive = true
            statusService.create(newStatus).then(_ => {
                console.log('Cписок статусов: ', statusList)
                console.log('Создан новый статус', _)
                statusList.push(_)
                console.log('Cписок статусов: ', statusList)
                setStatusList(statusList)
                setNewStatus(NEW_STATUS_TEMPLATE)
                navigate('/config/dictionaries/status')
            })
        }
    }

    const updateNewStatus = e => {
        e.preventDefault()
        statusService.update(updateStatus).then(_ => {
            setIsUpdate(false)
        })
    }

    const changeUpdateStatusProps = e => {
        updateStatus[e.target.name] = e.target.value
        setUpdateStatus(updateStatus)
    }

    const handleChange = (event, newEntity) => {
        setSelectedType(null)
        setEntity(newEntity);
        setIsUpdate(false);
        setNewStatus(NEW_STATUS_TEMPLATE)
    };
    const control = {
        value: entity,
        onChange: handleChange,
        exclusive: true,
    };

    const buttons = [
        <ToggleButton value="project" key="project">Проект</ToggleButton>,
        <ToggleButton value="value" key="value">Цель</ToggleButton>,
        <ToggleButton value="estimate" key="estimate">Оценка</ToggleButton>,
        <ToggleButton value="release" key="release">Релиз</ToggleButton>,
    ];

    const statusEdit = (e, i) => {
        e.preventDefault()
        setUpdateStatus(statusList[i])
        setIsUpdate(true)
    }

    const markToDelete = i => {
        const s = statusList[i]
        statusService.markToDelete(s.id, s.type_id).then(_ => {
            console.log("StatusList.jsx: [MarkToDelete]", _)
            setStatusList(_)
        })
    }

    const unmarkFromDelete = i => {
        const s = statusList[i]
        s.is_deleted = false
        statusService.update(s).then(_ => {
            let newStatusList = lodash.cloneDeep(statusList)
            newStatusList[i] = _
            setStatusList(newStatusList)
        })
    }



    const Statuses = ({statusList, statusEdit}) => {
        return statusList.map((s, i) => {
            return (<TableRow
                key={i} sx={{'&:last-child td, &:last-child th': {border: 0}}}
                onContextMenu={e => {
                    statusEdit(e, i)
                }}
                className={s.is_deleted ? "py-4 field_deleted" : "py-4"}>

                <TableCell scope="row">{s.name}</TableCell>
                <TableCell>{s.description}</TableCell>
                <TableCell align={"right"}>
                    {!s.is_deleted && <DeleteOutlineIcon onClick={e => markToDelete(i)} className="tech-text btn_delete"/>}
                    {s.is_deleted && <UndoIcon onClick={e => unmarkFromDelete(i)} className="tech-text btn_delete"/>}
                </TableCell>
            </TableRow>)
        })
    }

    const StatusCreateForm = ({createNewStatus}) => {
        return (<Box
            component="form"
            noValidate
            autoComplete="off"
            className="pa-24"
            onSubmit={createNewStatus}
            ref={createStatusForm}
        >
            <div>
                <TextField
                    id="outlined-required"
                    label="Новый статус"
                    name="name"
                    variant="standard"
                    className="w100"
                    autoFocus
                />
                <TextField
                    id="outlined-required"
                    label="Описание"
                    name="description"
                    variant="standard"
                    className="w100 mt-24"
                    multiline
                    rows={4}
                />
                <Button variant="outlined" color='success' className="mt-24"
                        type="submit">{"Создать новый статус"}</Button>
            </div>
        </Box>)
    }
    const renderInput = useCallback(params => {
        return <TextField {...params} label="Выберите тип" variant="standard"/>
    }, [selectedType, entity])

    const StatusUpdateForm = ({updateNewStatus, updateStatus, createNewStatus, changeUpdateStatusProps}) => {

        return (<Box
            component="form"
            noValidate
            autoComplete="off"
            className="pa-24"
            onSubmit={updateNewStatus}
            ref={updateStatusForm}
        >
            <div className="flex content-between small-text tech-text mb-24">
                <span>{"Создан: " + updateStatus.created_at_str}</span><span>{"Последнее обновление: " + updateStatus.updated_at_str}</span>
            </div>
            <div>
                <TextField
                    id="outlined-required"
                    name="name"
                    label="Новый статус"
                    variant="standard"
                    className="w100"
                    onChange={changeUpdateStatusProps}
                    defaultValue={updateStatus.name}
                />
                <TextField
                    id="outlined-required"
                    name="description"
                    defaultValue={updateStatus.description}
                    onChange={changeUpdateStatusProps}
                    label="Описание"
                    variant="standard"
                    className="w100 mt-24"
                    multiline
                    rows={4}
                />
                <div className="flex content-between">
                    <Button variant="outlined" color='success' className="mt-24"
                            type="submit">{"Обновить статус"}</Button>
                    <Button variant="outlined" color='primary' className="mt-24"
                            onClick={createNewStatus}>{"Создать новый статус"}</Button>
                </div>
            </div>
        </Box>)
    }

    if (loading) return <LinearProgress/>

    else return (<>
            <Grid container rowSpacing={1} p={2} columnSpacing={{xs: 1, sm: 2}}>
                <Grid item xs={6}>
                    <Paper>
                        <ToggleButtonGroup {...control} aria-label="Medium sizes">
                            {buttons}
                        </ToggleButtonGroup>
                    </Paper>
                </Grid>
            </Grid>
            {typeList && <Grid container rowSpacing={1} p={2} columnSpacing={{xs: 1, sm: 2}}>
                <Grid item xs={4}>
                    <Paper>
                        <Autocomplete
                            value={selectedType}
                            onChange={(event, newValue) => {
                                selectTypeFunction(event, newValue)
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue)
                            }}
                            disablePortal
                            fullWidth
                            id="combo-box-demo"
                            options={typeList}
                            getOptionLabel={t => t.name}
                            renderInput={(params) => renderInput(params)}
                            className="pa-24"
                        />
                        {isUpdate ? newStatus && <StatusUpdateForm
                            updateNewStatus={updateNewStatus}
                            updateStatus={updateStatus}
                            changeUpdateStatusProps={changeUpdateStatusProps}
                            createNewStatus={createNewStatus}
                        /> : <StatusCreateForm
                            newStatus={newStatus}
                            createNewStatus={createNewStatus}
                        />}
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Paper elevation={3} sx={{p: 2}}>
                        {statusList && <Table size="small" aria-label="a dense table" className="pa-8 ma-0">
                            <TableBody>
                                <Statuses statusEdit={statusEdit} statusList={statusList}/>
                            </TableBody>
                        </Table>}
                    </Paper>
                </Grid>
            </Grid>}
        </>
    )
}